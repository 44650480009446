import React from 'react';

const EmailIcon: React.FC = () => (
  <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.507812" width="48" height="48" rx="24" fill="#00A541" />
    <path
      d="M32.9239 15.579H16.0818C14.9239 15.579 13.9871 16.5263 13.9871 17.6842L13.9766 30.3158C13.9766 31.4737 14.9239 32.4211 16.0818 32.4211H32.9239C34.0818 32.4211 35.0292 31.4737 35.0292 30.3158V17.6842C35.0292 16.5263 34.0818 15.579 32.9239 15.579ZM32.9239 19.7895L24.5029 25.0527L16.0818 19.7895V17.6842L24.5029 22.9474L32.9239 17.6842V19.7895Z"
      fill="white"
    />
  </svg>
);
export default EmailIcon;
