// NOTE: gasby-config cannot resolve `stile-shared' as a workspace package
// must use relative imports in this file!
import { BLOG_URL, PAGE_SECTIONS, SHOP_URL } from '../../stile-shared/src/constants';
import type { PageData } from '../../stile-shared/src/utils/getPageData';

export * from '../../stile-shared/src/constants';

export const SITE_LOCALE = 'en-us';

/**
 * This is to assist with keeping page data in various places of the app in sync
 * E.g. Hero & NextPage blocks, Header & Footer menus
 * One-off or unsectioned pages (e.g. /other/ ) don't really need to be added here,
 * their seo, hero data etc is kept within their page files.
 */
export const PAGE_DATA: PageData = {
  'what-is-stile': {
    'stile-x': {
      seo: {
        title: 'Science Booklets That Make Learning Stick - Stile X',
        description:
          'Designed for middle school science, Stile X workbooks support retrieval practice, self-paced learning & active note-taking—all in one place.',
      },
      hero: {
        title: 'Stile X: Student workbooks',
        text: 'The ultimate student notebook and revision guide',
      },
      menu: { text: 'Stile X: Student workbooks', url: '/what-is-stile/stile-x/' },
    },

    'whats-included': {
      seo: {
        title: "What's included?",
        description:
          'Stile is a complete, comprehensive core curriculum for middle and high school. It’s battle-tested and highly refined, having been used (and loved!) by thousands of teachers for over 10 years.',
      },
      hero: {
        title: "What's included?",
        text: 'Stile is a complete, comprehensive core curriculum for middle and high school. It’s battle-tested and highly refined, having been used (and loved!) by thousands of teachers for over 10 years.',
      },
      menu: {
        text: "What's included?",
        url: '/what-is-stile/whats-included/',
      },
    },

    'stile-pl': {
      seo: {
        title: 'The PD Science Teachers Actually Use—Workshops, Coaching & More',
        description:
          'Practical, evidence-based professional learning for science teachers. Stile Academy offers self-paced training, 1:1 coaching & in-school workshops.',
      },
      hero: { title: 'Stile Professional Learning', text: 'The science of great science teaching' },
      menu: { text: 'Stile Professional Learning', url: '/what-is-stile/stile-pl/' },
    },

    'stile-classroom': {
      seo: {
        title: 'Stile in the classroom',
        description: 'World-class resources for direct instruction and self-paced learning',
      },
      hero: {
        title: 'Stile in the classroom',
        text: 'World-class curriculum resources for phenomena-based learning and explicit instruction',
      },
      menu: { text: 'Stile in the classroom', url: '/what-is-stile/stile-classroom/' },
    },

    'technology-for-teaching': {
      seo: {
        title: 'Smart Tools for Middle School Science Teachers | Customize, Teach, Track & Engage',
        description:
          'Technology that keeps teachers in control. Customize science lessons, track student progress & enhance engagement with NGSS & STEM-aligned tools.',
      },
      hero: {
        title: 'Technology for teaching',
        text: 'Purpose-built to empower teaching and learning in the classroom',
      },
      menu: { text: 'Technology for teaching', url: '/what-is-stile/technology-for-teaching/' },
    },

    'lab-kits': {
      seo: {
        title: 'Bring Science to Life with Stile’s Hands-On Lab Kits',
        description:
          'Stile Lab Kits make hands-on science easy, reliable, and flexible—tailored for middle school classrooms.',
      },
      hero: {
        title: 'Stile Lab Kits',
        text: 'Make science 1000% more hands-on',
      },
      menu: { text: 'Stile Lab Kits', url: '/what-is-stile/lab-kits/' },
    },

    'responsive-support': {
      seo: {
        title: 'Middle School Science Curriculum Support for Teachers & Leaders',
        description:
          'Need science curriculum help? Get 1:1 teacher coaching, lesson planning support & district-wide curriculum adoption assistance.',
      },
      hero: {
        title: 'Responsive support',
        text: 'Expert guidance and support to help your team succeed with Stile',
      },
      menu: {
        text: 'Responsive support',
        url: '/what-is-stile/responsive-support/',
      },
    },
  },

  states: {
    oregon: {
      seo: {
        title: 'NGSS-Aligned Science Curriculum  | Built for Oregon Middle Schools',
        description:
          'Middle school science teachers in Oregon—explore Stile! A fully NGSS-aligned, customizable 6th–8th grade science curriculum with engaging phenomena-based lessons, hands-on labs, simulations & more.',
      },
      hero: {
        title: 'Stile in Oregon',
        text: "Pilot Oregon's new, local science curriculum",
      },
      menu: { text: 'Stile in Oregon', url: '/states/oregon/' },
    },
    'oregon-alignment': {
      seo: {
        title: 'Stile in Oregon',
        description: "Pilot Oregon's new, local science curriculum",
      },
      hero: {
        title: 'Stile in Oregon',
        text: '',
      },
      menu: { text: 'Stile in Oregon', url: '/states/oregon-alignment/' },
    },
    'north-carolina': {
      seo: {
        title: 'Stile in North Carolina',
        description:
          'NC’s new science standards are here. Ensure your district has an engaging, standards-aligned, and NCDPI-approved curriculum built for student success.',
      },
      hero: {
        title: 'Stile in North Carolina',
        text: '',
      },
      menu: { text: 'Stile in North Carolina', url: '/states/north-carolina/' },
    },
    'north-carolina-alignment': {
      seo: {
        title: 'Stile in North Carolina',
        description: "Pilot North Carolina's new, local science curriculum",
      },
      hero: {
        title: 'Stile in North Carolina',
        text: '',
      },
      menu: { text: 'Stile in North Carolina', url: '/states/north-carolina-alignment/' },
    },
  },

  'why-choose-stile': {
    benefits: {
      seo: {
        title: 'Benefits of using Stile',
        description: 'Experience the benefits of using Stile in the classroom.',
      },
      hero: { title: 'Benefits', text: 'You’ll see the difference' },
      menu: { text: 'Benefits', url: '/why-choose-stile/benefits/' },
    },
    'high-quality-instructional-materials': {
      seo: {
        title: 'What are High-Quality Instructional Materials for Teachers? | Stile Education',
        description:
          'Teaching resources built on evidence-based research and HQIM approved. Create lessons that stick, engage students, and transform learning. Get the checklist now.',
      },
      hero: { title: 'High-quality instructional materials', text: '' },
      menu: {
        text: 'High-quality instructional materials',
        url: '/why-choose-stile/high-quality-instructional-materials/',
      },
    },

    'evidence-of-efficacy': {
      seo: {
        title: 'Evidence of efficacy',
        description: 'Hear from teachers who are seeing the difference using Stile',
        uniqueToLocale: true,
      },
      hero: { title: 'Evidence of efficacy', text: 'Teachers are seeing the difference' },
      menu: { text: 'Evidence of efficacy', url: '/why-choose-stile/evidence-of-efficacy/' },
    },

    'evidence-based-pedagogy': {
      seo: {
        title: 'Evidence-based pedagogy',
        description: 'Stile helps teachers implement evidence-based teaching strategies',
      },
      hero: {
        title: 'Evidence-based pedagogy',
        text: 'Stile helps teachers implement evidence-based teaching strategies',
      },
      menu: { text: 'Evidence-based pedagogy', url: '/why-choose-stile/evidence-based-pedagogy/' },
    },

    stem: {
      seo: {
        title: 'A true STEM education',
        description:
          'Connect the dots with Math, Technology and Engineering, and help students get a true STEM education.',
      },
      hero: {
        title: 'A true STEM education',
        text: 'Connect the dots with Math, Technology and Engineering',
      },
      menu: { text: 'A true STEM education', url: '/why-choose-stile/stem/' },
    },

    'boost-reading-writing-and-math': {
      seo: {
        title: 'Science Teachers: Strengthen Literacy & Math in Your Classroom',
        description:
          'Improve literacy & numeracy in middle school science! Stile embeds reading, writing & math into every lesson, supporting Common Core & NGSS learning.',
        uniqueToLocale: true,
      },
      hero: {
        title: 'Boost reading, writing and math',
        text: 'Stile deeply integrates the Common Core Standards.',
      },
      menu: {
        text: 'Boost reading, writing and math',
        url: '/why-choose-stile/boost-reading-writing-and-math/',
      },
    },

    'what-teachers-are-saying': {
      seo: {
        title: 'Recommended by teachers | Teachers’ testimonials',
        description:
          'Thousands of teachers use Stile every week. Learn more about their experiences with Stile!',
      },
      hero: {
        title: 'Recommended by teachers',
        text: 'Thousands of teachers use Stile every week',
      },
      menu: { text: 'Recommended by teachers', url: '/why-choose-stile/what-teachers-are-saying/' },
    },
    'oregon-pilot': {
      seo: {
        title: 'Oregon Science Teachers: Test Drive Stile’s NGSS Curriculum',
        description:
          'Oregon educators: Test out a complete NGSS-aligned science curriculum with hands-on & digital learning. Try a Stile pilot in your school today!',
      },
      hero: {
        title: 'The perfect pilot',
        text: '',
      },
      menu: { text: 'The perfect pilot', url: '/why-choose-stile/oregon-pilot/' },
    },
  },

  'who-we-are': {
    apply: {
      seo: {
        title: 'Apply for a job at Stile',
        description: 'Join the Stile team and improve the state of science education globally.',
        opengraphImageSelection: 'apply',
      },
      hero: { title: '', text: '' },
      menu: { text: '', url: '/who-we-are/apply/' },
    },

    'effective-stilists': {
      seo: {
        title: 'Effective Stilists',
        description: 'Stile team values',
      },
      hero: { title: 'Effective Stilists', text: 'Our team values' },
      menu: { text: 'Effective Stilists', url: '/who-we-are/effective-stilists/' },
    },

    'our-why': {
      seo: {
        title: 'Our why',
        description: 'A world-class science education for every student',
      },
      hero: { title: 'Our why', text: 'A world-class science education for every student' },
      menu: { text: 'Our why', url: '/who-we-are/our-why/' },
    },

    'our-principles': {
      seo: {
        title: 'Our guarantees and principles',
        description:
          'From supporting teachers and lifelong learning to personalised experiences, our core values guide everything we do. Learn more about Stile here!',
      },
      hero: {
        title: 'Our guarantees and principles',
        text: 'The core beliefs that guide everything we do',
      },
      menu: { text: 'Our guarantees and principles', url: '/who-we-are/our-principles/' },
    },

    'our-team': {
      seo: {
        title: 'Our team',
        description: 'We share a passion for great science education',
      },
      hero: { title: 'Our team', text: 'We share a passion for great science education' },
      menu: { text: 'Our team', url: '/who-we-are/our-team/' },
    },

    'join-the-team': {
      seo: {
        title: 'Join the team',
        description: 'Stile employees share a passion for great science education.',
        opengraphImageSelection: 'apply',
      },
      hero: { title: 'Join the team', text: 'We share a passion for great science education' },
      menu: { text: 'Join the team', url: '/who-we-are/join-the-team/' },
    },

    'gender-diversity-in-stem': {
      seo: {
        title: 'Celebrating gender diversity in STEM',
        description: 'Building a more equitable future.',
      },
      hero: {
        title: 'Celebrating gender diversity in STEM',
        text: 'Building a more equitable future',
      },
      menu: { text: 'Gender diversity in STEM', url: '/who-we-are/gender-diversity-in-stem/' },
    },

    'engineering-at-stile': {
      seo: {
        title: 'Engineering at Stile',
        description: 'Building tools that improve the state of science education globally.',
      },
      hero: {
        title: 'Being a software engineer at Stile',
        text: 'Build tools that improve the state of science education globally',
      },
      menu: { text: 'Engineering at Stile', url: '/who-we-are/engineering-at-stile/' },
    },

    'hiring-process': {
      seo: {
        title: 'Hiring process',
        description:
          'Stile aspires to build a team of exceptional individuals who are a genuine delight to work with every day.',
      },
      hero: { title: 'Hiring process', text: 'Building a team of exceptional individuals' },
      menu: { text: 'Hiring process', url: '/who-we-are/hiring-process/' },
    },

    'life-at-stile': {
      seo: {
        title: 'Careers | Life at Stile',
        description:
          'Stile is for those who want to make an impact and improve our education system. Learn more about our culture!',
      },
      hero: { title: 'Life at Stile', text: 'Spending time with our brilliant team' },
      menu: { text: 'Life at Stile', url: '/who-we-are/life-at-stile/' },
    },

    'upcoming-events': {
      seo: {
        title: 'Upcoming events',
        description: 'Fun events for science educators',
        uniqueToLocale: true,
      },
      hero: { title: 'Upcoming events', text: 'Fun events for science educators' },
      menu: { text: 'Upcoming events', url: '/who-we-are/upcoming-events' },
    },

    blog: {
      seo: {
        title: 'Stile Blog',
        description: "Stile's blog",
      },
      hero: { title: 'Stile Blog', text: 'Stile blog' },
      menu: { text: 'Stile Blog', url: 'https://blog.stileeducation.com/' },
    },

    contact: {
      seo: {
        title: 'Contact us',
        description: 'Stile Education contact details',
      },
      hero: { title: 'Contact us', text: 'We’re here to help' },
      menu: { text: 'Contact us', url: '/who-we-are/contact/' },
    },
  },

  simulations: {
    index: {
      seo: {
        title: 'Free NGSS Science Simulations for Middle & High School | Stile',
        description:
          'Engage students with free NGSS-aligned science simulations. Explore interactive physics, chemistry, biology, math and earth science for middle & high school.',
      },
      hero: {
        title: 'Engaging Science Simulations for Students',
        text: 'Interactive virtual science experiments that make learning hands-on and exciting.',
      },
      menu: { text: 'All science simulations', url: '/simulations/' },
    },
    chemistry: {
      seo: {
        title: 'Chemistry simulations',
        description: 'Interactive chemistry simulations for high school students',
      },
      hero: {
        title: 'Chemistry simulations',
        text: 'Interactive chemistry simulations for high school students',
      },
      menu: { text: 'Chemistry simulations', url: '/simulations/chemistry/' },
    },
    physics: {
      seo: {
        title: 'Physics simulations',
        description: 'Interactive physics simulations for high school students',
      },
      hero: {
        title: 'Physics simulations',
        text: 'Interactive physics simulations for high school students',
      },
      menu: { text: 'Physics simulations', url: '/simulations/physics/' },
    },
    biology: {
      seo: {
        title: 'Biology simulations',
        description: 'Interactive biology simulations for high school students',
      },
      hero: {
        title: 'Biology simulations',
        text: 'Interactive biology simulations for high school students',
      },
      menu: { text: 'Biology simulations', url: '/simulations/biology/' },
    },
    'earth-science': {
      seo: {
        title: 'Earth science simulations',
        description: 'Interactive earth science simulations for high school students',
      },
      hero: {
        title: 'Earth science simulations',
        text: 'Interactive earth science simulations for high school students',
      },
      menu: { text: 'Earth and space science simulations', url: '/simulations/earth-science/' },
    },
    math: {
      seo: {
        title: 'Math simulations',
        description: 'Interactive math simulations for high school students',
      },
      hero: {
        title: 'Math simulations',
        text: 'Interactive math simulations for high school students',
      },
      menu: { text: 'Math simulations', url: '/simulations/math/' },
    },
  },

  other: {
    resources: {
      seo: {
        title: 'Stile resources',
        description:
          'Browse our teaching resources to elevate teaching and learning experiences. From classroom to lesson planning resources, discover Stile’s teaching aids now.',
      },
      hero: {
        title: 'Stile resources',
        text: 'Resources to elevate teaching and learning experiences',
      },
      menu: { text: 'Stile resources', url: '/other/resources/' },
    },
  },
};

/* These can vary between locales */
export const HEADER_LINK_SECTIONS = [
  {
    title: PAGE_SECTIONS['what-is-stile'],
    links: [
      PAGE_DATA['what-is-stile']['whats-included'].menu,
      PAGE_DATA['what-is-stile']['stile-classroom'].menu,
      PAGE_DATA['what-is-stile']['stile-x'].menu,
      PAGE_DATA['what-is-stile']['lab-kits'].menu,
      PAGE_DATA['what-is-stile']['stile-pl'].menu,
      PAGE_DATA['what-is-stile']['technology-for-teaching'].menu,
      PAGE_DATA['what-is-stile']['responsive-support'].menu,
    ],
  },
  {
    title: PAGE_SECTIONS['why-choose-stile'],
    links: [
      PAGE_DATA['why-choose-stile']['benefits'].menu,
      PAGE_DATA['why-choose-stile']['high-quality-instructional-materials'].menu,
      PAGE_DATA['why-choose-stile']['evidence-of-efficacy'].menu,
      PAGE_DATA['why-choose-stile']['evidence-based-pedagogy'].menu,
      PAGE_DATA['why-choose-stile']['stem'].menu,
      PAGE_DATA['why-choose-stile']['boost-reading-writing-and-math'].menu,
      PAGE_DATA['why-choose-stile']['what-teachers-are-saying'].menu,
      PAGE_DATA['states']['north-carolina'].menu,
      PAGE_DATA['states']['oregon'].menu,
    ],
  },
  {
    title: PAGE_SECTIONS['who-we-are'],
    links: [
      PAGE_DATA['who-we-are']['our-why'].menu,
      PAGE_DATA['who-we-are']['our-principles'].menu,
      PAGE_DATA['who-we-are']['our-team'].menu,
      PAGE_DATA['who-we-are']['join-the-team'].menu,
      PAGE_DATA['who-we-are']['gender-diversity-in-stem'].menu,
      PAGE_DATA['who-we-are']['upcoming-events'].menu,
      PAGE_DATA['who-we-are']['blog'].menu,
      PAGE_DATA['who-we-are']['contact'].menu,
    ],
  },
  {
    title: PAGE_SECTIONS['simulations'],
    links: [
      PAGE_DATA['simulations']['index'].menu,
      PAGE_DATA['simulations']['biology'].menu,
      PAGE_DATA['simulations']['chemistry'].menu,
      PAGE_DATA['simulations']['earth-science'].menu,
      PAGE_DATA['simulations']['math'].menu,
      PAGE_DATA['simulations']['physics'].menu,
    ],
  },
];

/* These can vary between locales */
export const FOOTER_LINK_SECTIONS = [
  {
    title: PAGE_SECTIONS['what-is-stile'],
    links: [
      PAGE_DATA['what-is-stile']['stile-classroom'].menu,
      PAGE_DATA['what-is-stile']['stile-x'].menu,
      PAGE_DATA['what-is-stile']['whats-included'].menu,
      PAGE_DATA['what-is-stile']['lab-kits'].menu,
      PAGE_DATA['what-is-stile']['stile-pl'].menu,
      PAGE_DATA['what-is-stile']['technology-for-teaching'].menu,
      PAGE_DATA['what-is-stile']['responsive-support'].menu,
    ],
  },
  {
    title: PAGE_SECTIONS['why-choose-stile'],
    links: [
      PAGE_DATA['why-choose-stile']['benefits'].menu,
      PAGE_DATA['why-choose-stile']['high-quality-instructional-materials'].menu,
      PAGE_DATA['why-choose-stile']['evidence-of-efficacy'].menu,
      PAGE_DATA['why-choose-stile']['evidence-based-pedagogy'].menu,
      PAGE_DATA['why-choose-stile']['stem'].menu,
      PAGE_DATA['why-choose-stile']['boost-reading-writing-and-math'].menu,
      PAGE_DATA['why-choose-stile']['what-teachers-are-saying'].menu,
      PAGE_DATA['states']['north-carolina'].menu,
      PAGE_DATA['states']['oregon'].menu,
    ],
  },
  {
    title: PAGE_SECTIONS['who-we-are'],
    links: [
      PAGE_DATA['who-we-are']['our-why'].menu,
      PAGE_DATA['who-we-are']['our-principles'].menu,
      PAGE_DATA['who-we-are']['our-team'].menu,
      PAGE_DATA['who-we-are']['join-the-team'].menu,
      PAGE_DATA['who-we-are']['gender-diversity-in-stem'].menu,
      PAGE_DATA['who-we-are']['engineering-at-stile'].menu,
      PAGE_DATA['who-we-are']['upcoming-events'].menu,
      PAGE_DATA['who-we-are']['hiring-process'].menu,
      PAGE_DATA['who-we-are']['life-at-stile'].menu,
      PAGE_DATA['who-we-are']['contact'].menu,
    ],
  },
  {
    title: PAGE_SECTIONS['simulations'],
    links: [
      PAGE_DATA['simulations']['index'].menu,
      PAGE_DATA['simulations']['physics'].menu,
      PAGE_DATA['simulations']['chemistry'].menu,
      PAGE_DATA['simulations']['biology'].menu,
      PAGE_DATA['simulations']['earth-science'].menu,
      PAGE_DATA['simulations']['math'].menu,
    ],
  },
];

export const OTHER_SITE_LINKS = [
  { text: 'Privacy', url: '/other/privacy/' },
  { text: 'Security', url: '/other/security/' },
  { text: 'Terms', url: '/other/terms/' },
  { text: 'Blog', url: BLOG_URL },
  { text: 'Resources', url: '/other/resources/' },
  { text: 'Shop', url: SHOP_URL },
];
