import '@reach/dialog/styles.css';

import * as React from 'react';

import { DialogContent, DialogOverlay } from '@reach/dialog';
import iconDesktop from 'stile-shared/assets/icons/icon_desktop.svg';
import styled, { createGlobalStyle, css } from 'styled-components';

import { IconButton } from 'stile-shared/src/components/2020/common/IconButton';
import { Icon } from 'stile-shared/src/components/2024/common/Icon';

import { Button } from '../../Button';

export type ModalProps = React.PropsWithChildren<{
  closeModal: () => void;
  isOpen: boolean;
  label: string;
  width?: string;
  showCloseButton?: boolean;
  externalCloseButton?: boolean;
}>;

// Blur the main site behind the modal
const GlobalStyle = createGlobalStyle`
  #___gatsby {
    filter: blur(2px);
  }
`;

const MobileBanner = styled.div`
  display: none;
  color: var(--white);
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid var(--stile-green-1);
  background: var(--stile-green-3);
  padding: 12px 20px;
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 769px) {
    display: none;
  }
`;

export const Modal = ({
  isOpen,
  closeModal,
  label,
  width,
  externalCloseButton,
  showCloseButton = true,
  children,
}: ModalProps) => {
  const ariaLabel = `${label} Dialog`;

  // Track whether the "Are you sure?" confirm popup is visible
  const [showExitConfirm, setShowExitConfirm] = React.useState(false);

  // When user clicks the close button, show the confirm pop-up
  const handleAttemptClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowExitConfirm(true);
  };

  // If user says "No, go back," just hide the confirm pop-up
  const handleConfirmNo = () => {
    setShowExitConfirm(false);
  };

  // If user says "Yes, exit," close both pop-up and the modal
  const handleConfirmYes = () => {
    setShowExitConfirm(false);
    closeModal();
  };

  return (
    <>
      {isOpen && (
        <StyledDialogOverlay>
          <GlobalStyle />
          <StyledDialogContent aria-label={ariaLabel} $width={width}>
            <MobileBanner>
              <Icon src={iconDesktop} iconSize="38px" ariaText="Desktop Icon" />
              For the best experience open this on your computer.
            </MobileBanner>
            {children}
            {showCloseButton && (
              <CloseButton onClick={handleAttemptClose} $externalCloseButton={externalCloseButton}>
                <Icon iconId="close" iconSize="fontSize" ariaText={`Close ${ariaLabel}`} />
                <h6 className="h8">Close</h6>
              </CloseButton>
            )}
          </StyledDialogContent>
          {showExitConfirm && (
            <ConfirmOverlay>
              <ConfirmContent aria-label="Confirm exit">
                <div className="header">
                  <h6 className="h7">Confirm exit</h6>
                </div>
                <div className="footer">
                  <p>Are you sure you want to exit? Your progress will not be saved.</p>
                  <ButtonRow>
                    <Button onClick={handleConfirmNo} label="No, go back" style="secondary" />
                    <Button
                      onClick={handleConfirmYes}
                      label="Yes, exit"
                      style="secondary"
                      className="red"
                    />
                  </ButtonRow>
                </div>
              </ConfirmContent>
            </ConfirmOverlay>
          )}
        </StyledDialogOverlay>
      )}
    </>
  );
};

export const StyledDialogOverlay = styled(DialogOverlay)`
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
`;

export const StyledDialogContent = styled(DialogContent)<{ $width?: string }>`
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  padding: 30px 55px;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  overflow-y: auto;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 30px 25px;
  }
`;

export const CloseButton = styled(IconButton)<{ $externalCloseButton?: boolean }>`
  color: var(--white);
  background-color: transparent;
  border: 0px;
  font-size: 30px;
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  flex-direction: column;
  .h8 {
    margin: 0 !important;
  }
  @media (max-width: 768px) {
    flex-direction: row;
  }
  ${({ $externalCloseButton }) =>
    $externalCloseButton &&
    css`
      top: -4px;
      right: -4px;
      transform: translateY(-100%);
    `};
`;

export const ConfirmOverlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 10000; /* Above the modal */
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
`;

export const ConfirmContent = styled.div`
  max-width: 560px;
  min-width: 320px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  margin: 25px;
  border: 0.5px solid rgba(0, 0, 0, 0);
  background: white;
  box-shadow: 0px 43px 26px 0px rgba(0, 0, 0, 0.04), 0px 19px 19px 0px rgba(0, 0, 0, 0.06),
    0px 5px 11px 0px rgba(0, 0, 0, 0.07);

  .footer {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 28px;
    align-self: stretch;
    p {
      margin: 0;
    }
  }
  .header {
    display: flex;
    align-self: stretch;
    flex-wrap: wrap;
    padding: 16px 24px;
    border-bottom: 1px solid #eaecef;
    .h7 {
      margin: 0;
    }
  }
`;

export const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  .button {
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid var(--Primary-Stile-Grey-5, #928d88);
  }
  .red {
    background: var(--Messaging-Danger, #b10307);
    color: var(--white);
    border: 1px solid var(--Messaging-Danger, #b10307);
  }
`;
