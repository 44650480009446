import { createGlobalStyle } from 'styled-components';

import { fonts } from './fonts';
import { normalize } from './normalize';
import { storybook } from './storybook';
import { typography } from './typography';

export const GlobalStyles = createGlobalStyle`

    ${normalize}

    * {
      max-width: none;
      box-sizing: border-box;
    }

:root {
    --stile-green-1: #00e65a;
    --stile-green-2: #00cb50;
    --stile-green-3: #00a541;

    --white: #ffffff;

    --stile-grey-1: #f7f5f3;
    --stile-grey-2: #ece9e5;
    --stile-grey-3: #dad4cc;
    --stile-grey-4: #b5b0a9;
    --stile-grey-5: #918c87;
    --stile-grey-6: #706d69;
    --stile-grey-7: #4f4d4a;
    --stile-grey-8: #2b2b2b;

    --stile-blue-1: #2469ff;
    --stile-blue-2: #1d54c9;
    --stile-blue-3: #12347f;
    --stile-blue-4: #0d275b;

    --stile-teal-1: #5bdbd5;
    --stile-teal-2: #35c3bd;
    --stile-teal-3: #0c9d97;
    --stile-teal-4: #047a75;
    --stile-teal-5: #005e5b;

    --stile-orange-1: #ff9817;
    --stile-orange-2: #ff5c3b;
    --stile-orange-3: #cc4b31;

    --stile-cherry-1: #d13565;
    --stile-cherry-2: #ad2b53;
    --stile-cherry-3: #801f3c;

    /* Old Style Stile Styles */

    --space-3xs: clamp(0.25rem,calc(0.23rem + 0.09vw),0.31rem);
    --space-2xs: clamp(0.5rem,calc(0.45rem + 0.27vw),0.69rem);
    --space-xs: clamp(0.75rem,calc(0.68rem + 0.36vw),1rem);
    --space-s: clamp(1rem,calc(0.91rem + 0.45vw),1.31rem);
    --space-m: clamp(1.5rem,calc(1.36rem + 0.71vw),2rem);
    --space-l: clamp(2rem,calc(1.82rem + 0.89vw),2.63rem);
    --space-xl: clamp(3rem,calc(2.73rem + 1.34vw),3.94rem);
    --space-2xl: clamp(4rem,calc(3.64rem + 1.79vw),5.25rem);
    --space-3xl: clamp(6rem,calc(5.46rem + 2.68vw),7.88rem);
    --space-3xs-2xs: clamp(0.25rem,calc(0.13rem + 0.63vw),0.69rem);
    --space-2xs-xs: clamp(0.5rem,calc(0.36rem + 0.71vw),1rem);
    --space-xs-s: clamp(0.75rem,calc(0.59rem + 0.8vw),1.31rem);
    --space-s-m: clamp(1rem,calc(0.71rem + 1.43vw),2rem);
    --space-m-l: clamp(1.5rem,calc(1.18rem + 1.61vw),2.63rem);
    --space-l-xl: clamp(2rem,calc(1.45rem + 2.77vw),3.94rem);
    --space-xl-2xl: clamp(3rem,calc(2.36rem + 3.21vw),5.25rem);
    --space-2xl-3xl: clamp(4rem,calc(2.89rem + 5.54vw),7.88rem);
    
    --step--2: clamp(0.65rem,calc(0.65rem + 0.02vw),0.67rem);
    --step--1: clamp(0.78rem,calc(0.75rem + 0.16vw),0.89rem);
    --step--05: clamp(0.86rem,calc(0.81rem + 0.26vw),1.04rem);
    --step-0: clamp(0.94rem,calc(0.87rem + 0.36vw),1.19rem);
    --step-1: clamp(1.13rem,calc(0.99rem + 0.65vw),1.58rem);
    --step-2: clamp(1.35rem,calc(1.13rem + 1.09vw),2.11rem);
    --step-3: clamp(1.62rem,calc(1.28rem + 1.7vw),2.81rem);
    --step-4: clamp(1.94rem,calc(1.43rem + 2.58vw),3.75rem);
    --step-5: clamp(2.33rem,calc(1.57rem + 3.81vw),5rem);
  }

  .content {
    display: flex;
    flex-direction: column;
    /* min-height: 100vh; */
    margin: 0 auto;
  }

  .hide-desktop {
    display: none;
  }

  @media (min-width: 1220px) {
    .content {
      width: 1160px;
    }
  }

  @media (max-width: 1220px) {
    .content {
      margin: 0 60px;
    }
  }

  @media (max-width: 768px) {
    .hide-mobile {
      display: none;
    }
    .hide-desktop {
      display: block;
    }

    .content {
      margin: 0 25px;
    }
  }

  hr {
    border: none;
    border-top: 1px solid var(--stile-grey-5);
    margin: 20px 0;
  }

  /* hacky hackey */
  reach-portal a {
    line-height: 1.15;
    text-decoration: none;
  }

  .footer {
    .content {
      padding-top: 0 !important;
      .grid-layout {
        margin: 0 !important;
        padding-top: 60px !important;
      } 
    }
  }


  ${fonts}
  ${typography}
  ${storybook}
`;
