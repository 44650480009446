import React from 'react';

import { StyledWITBGridItem } from './WITBGridItem.styles';

interface WITBGridItemProps {
  iconImage?: JSX.Element;
  title: string;
  detail: string;
}

const WITBGridItem: React.FC<WITBGridItemProps> = ({ iconImage, title, detail }) => (
  <StyledWITBGridItem>
    <div className="icon-title-container">
      {iconImage && <div className="icon-container">{iconImage}</div>}
      <h6 className="grid-title">{title}</h6>
    </div>
    <div className="grid-content">
      <p>{detail}</p>
    </div>
  </StyledWITBGridItem>
);

export default WITBGridItem;
