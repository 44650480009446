import React from 'react';

import { StyledAsProps } from 'stile-shared/src/types/props';

import { StyledWITBTopSection } from './WITBTopSection.styles';

type WITBTopSectionProps = React.HTMLAttributes<HTMLSpanElement> &
  StyledAsProps & {
    title: string;
    description: string | JSX.Element;
    headingStyle?: 'huge';
  };

const WITBTopSection: React.FC<WITBTopSectionProps> = ({ title, description, headingStyle }) => (
  <StyledWITBTopSection>
    <h1 className={headingStyle === 'huge' ? '--huge' : ''}>{title}</h1>
    <p>{description}</p>
  </StyledWITBTopSection>
);

export default WITBTopSection;
