import React, { useState } from 'react';

import dropdownArrow from 'stile-shared/assets/icons/dropdown_arrow.svg';
import styled from 'styled-components';

type ReferencesDropdownProps = {
  references: string[];
};

const DropdownContainer = styled.div`
  width: 100%;
  padding-top: 130px;
  padding-bottom: 26px;
  @media (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: 40px;
  }
`;

const DropdownHeader = styled.div<{ isOpen: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: color 0.3s ease-in-out;
  margin-bottom: 10px;

  h6 {
    color: var(--stile-grey-5);
    margin: 0;
  }

  &:hover {
    color: var(--stile-green-3);
    filter: brightness(0) saturate(100%) invert(49%) sepia(71%) saturate(501%) hue-rotate(88deg)
      brightness(98%) contrast(90%);
  }

  .arrow {
    display: inline-block;
    margin-left: 5px;
    height: 20px;
    width: 20px;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
    transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
  }
`;

const DropdownContent = styled.div<{ isOpen: boolean }>`
  max-height: ${({ isOpen }) => (isOpen ? '500px' : '0px')};
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
`;

const ReferencesList = styled.ol`
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 21px;
`;

const ReferencesDropdown: React.FC<ReferencesDropdownProps> = ({ references }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownContainer>
      <DropdownHeader isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <h6 className="h8">REFERENCES</h6>
        <img src={dropdownArrow} alt="Dropdown Arrow" className="arrow" />
      </DropdownHeader>
      <DropdownContent isOpen={isOpen}>
        <ReferencesList>
          {references.map((ref, index) => (
            <li key={index}>
              {index + 1}. {ref}
            </li>
          ))}
        </ReferencesList>
      </DropdownContent>
    </DropdownContainer>
  );
};
export default ReferencesDropdown;
