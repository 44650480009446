import '@splidejs/react-splide/css';

import React from 'react';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import styled from 'styled-components';

interface ReviewProps {
  name: string;
  school: string;
  quote: string;
}

interface ReviewsSliderProps {
  reviews: ReviewProps[];
  header?: string;
}

function Review({
  name,
  school,
  quote,
  ...props
}: ReviewProps & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props}>
      <p className="review">{quote}</p>
      <div className="reviewer">
        <p className="--small">{name}</p>
        <p className="--small">{school}</p>
      </div>
    </div>
  );
}
const ReviewsSection = styled.div`
  padding-bottom: 0;
  background-color: transparent;
  margin-left: calc(50% - 50vw - 8px);
  width: 100vw;

  .splide {
    background-color: transparent;
    .splide__track {
      padding-bottom: 204px;
      background-color: transparent;
    }
    &:nth-child(2) {
      margin-top: -178px;
      .splide__track {
        padding-bottom: 100px;

        @media (max-width: 768px) {
          padding-bottom: 60px;
        }
      }
    }
  }

  &.mobile {
    display: none;
  }
  @media (max-width: 768px) {
    &.desktop {
      display: none;
    }
    &.mobile {
      display: block;
    }
  }
`;
const StyledReview = styled(Review)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px 28px 25px;
  align-items: flex-start;
  border-radius: 20px;
  background: var(--white);
  .review {
    font-size: 18px;
    line-height: 26px;
  }
  .reviewer {
    .--small {
      color: var(stile-grey-6) !important;
    }
  }
  height: 218px;
  width: 500px;

  @media (max-width: 768px) {
    width: 95%;
    height: 250px;
  }

  box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.01), 0px 20px 20px 0px rgba(0, 0, 0, 0.01),
    0px 130px 78px 0px rgba(0, 0, 0, 0.03), 0px 58px 58px 0px rgba(0, 0, 0, 0.04),
    0px 14px 32px 0px rgba(0, 0, 0, 0.05);

  p,
  h6 {
    padding: 0%;
    margin: 0;
  }
`;

const ReviewsSlider: React.FC<ReviewsSliderProps> = ({ reviews }) => {
  const splideOptions = {
    type: 'loop', // Loop back to the beginning when reaching the end
    perPage: 3, // Number of items visible per page
    perMove: 1, // Move one item at a time
    rewind: false, // Rewind to start when the end is reached
    pagination: false, // Enable pagination dots
    gap: '28px', // Gap between slides
    width: '100%', // Width of the slider
    fixedWidth: '500px', // Fixed width of each slide
    fixedHeight: '204px;', // Fixed height of each slide
    arrows: false, // Hide arrows
    trimSpace: true, // Trim space at the edges
    autoScroll: {
      pauseOnHover: true, // Do not pause scrolling when hovering over the carousel
      pauseOnFocus: true, // Do not pause scrolling when the carousel is focused
      rewind: true, // Rewind to start when the end is reached
      speed: 0.75, // Scrolling speed
    },
  };

  const sliderOptionsMobile = {
    ...splideOptions,
    perPage: 1,
    fixedWidth: '95%',
    fixedHeight: '250px',
    gap: '12px',
  };

  return (
    <>
      <ReviewsSection className="desktop">
        <Splide options={splideOptions} extensions={{ AutoScroll }}>
          {reviews.slice(0, 6).map((review, index) => (
            <SplideSlide key={index}>
              <StyledReview {...review} />
            </SplideSlide>
          ))}
        </Splide>

        <Splide
          options={{
            ...splideOptions,
            autoScroll: {
              ...splideOptions.autoScroll,
              speed: splideOptions.autoScroll.speed * 1.33,
            },
          }}
          extensions={{ AutoScroll }}
        >
          {reviews.slice(6).map((review, index) => (
            <SplideSlide key={index}>
              <StyledReview {...review} />
            </SplideSlide>
          ))}
        </Splide>
      </ReviewsSection>
      <ReviewsSection className="mobile">
        <Splide options={sliderOptionsMobile} extensions={{ AutoScroll }}>
          {reviews.slice(0, 6).map((review, index) => (
            <SplideSlide key={index}>
              <StyledReview {...review} />
            </SplideSlide>
          ))}
        </Splide>

        <Splide
          options={{
            ...sliderOptionsMobile,
            autoScroll: {
              ...sliderOptionsMobile.autoScroll,
              speed: sliderOptionsMobile.autoScroll.speed * 1.33,
            },
          }}
          extensions={{ AutoScroll }}
        >
          {reviews.slice(6).map((review, index) => (
            <SplideSlide key={index}>
              <StyledReview {...review} />
            </SplideSlide>
          ))}
        </Splide>
      </ReviewsSection>
    </>
  );
};

export default ReviewsSlider;
