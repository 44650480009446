import React from 'react';

const PinterestIcon: React.FC = () => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M100 10C50.293 10 10 50.293 10 100C10 138.133 33.724 170.695 67.204 183.808C66.421 176.689 65.701 165.763 67.519 157.996C69.157 150.976 78.067 113.257 78.067 113.257C78.067 113.257 75.376 107.866 75.376 99.901C75.376 87.382 82.63 78.049 91.657 78.049C99.334 78.049 103.042 83.818 103.042 90.721C103.042 98.443 98.128 109.99 95.59 120.682C93.466 129.646 100.081 136.945 108.919 136.945C124.912 136.945 137.206 120.079 137.206 95.734C137.206 74.188 121.726 59.122 99.613 59.122C74.008 59.122 58.978 78.328 58.978 98.182C58.978 105.913 61.957 114.211 65.674 118.72C66.412 119.611 66.511 120.394 66.295 121.303C65.611 124.147 64.099 130.258 63.802 131.509C63.415 133.156 62.497 133.507 60.796 132.706C49.555 127.468 42.526 111.034 42.526 97.84C42.526 69.454 63.154 43.372 101.998 43.372C133.219 43.372 157.483 65.62 157.483 95.356C157.483 126.379 137.926 151.336 110.782 151.336C101.665 151.336 93.088 146.593 90.154 141.004C90.154 141.004 85.636 158.185 84.547 162.406C82.513 170.218 77.032 180.028 73.36 186.004C81.775 188.587 90.721 190 100 190C149.698 190 190 149.698 190 100C190 50.302 149.707 10 100 10Z"
      fill="#E60023"
    />
  </svg>
);

export default PinterestIcon;
