import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

interface SwipeIndicatorProps {
  containerRef: React.RefObject<HTMLDivElement>;
  totalItems: number;
  variant?: 'secondary'; // if not provided, will use default styling
}

interface PageIndicatorProps {
  isActive: boolean;
  variant?: 'secondary';
}

const Container = styled.div<{ variant?: 'secondary' }>`
  width: auto;
  height: 21px;
  position: relative;
  margin: 0 0 0 auto;
  display: flex;
  justify-content: ${({ variant }) => (variant === 'secondary' ? 'center' : 'flex-end')};
  z-index: 10;

  @media (max-width: 768px) {
    justify-content: ${({ variant }) => (variant === 'secondary' ? 'center' : 'flex-start')};
  }
`;

const IndicatorWrapper = styled.div`
  display: inline-flex;
  align-self: stretch;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
`;

const DotsWrapper = styled.div`
  padding-left: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
`;

const PageIndicator: React.FC<PageIndicatorProps> = ({ isActive, variant }) => {
  const activeStroke = variant === 'secondary' ? 'var(--white)' : 'var(--stile-green-3)';
  const inactiveFill = variant === 'secondary' ? 'var(--stile-green-1)' : 'var(--stile-grey-5)';

  return isActive ? (
    <svg width="26" height="7" viewBox="0 0 26 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 3.5H23" stroke={activeStroke} strokeWidth="6" strokeLinecap="round" />
    </svg>
  ) : (
    <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="3" cy="3.5" r="3" fill={inactiveFill} />
    </svg>
  );
};

const PageText = styled.p<{ variant?: 'secondary' }>`
  color: ${({ variant }) => (variant === 'secondary' ? '#var(--white)' : 'var(--stile-grey-6)')};
  width: auto;
  font-size: 14px;
  white-space: nowrap;
`;

const SwipeIndicator: React.FC<SwipeIndicatorProps> = ({ containerRef, totalItems, variant }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const updatePage = () => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, offsetWidth } = containerRef.current;
      const containerWidth = offsetWidth || 1;
      // Calculate total pages (rounding up to include fractional pages)
      const calculatedTotalPages = Math.ceil(scrollWidth / containerWidth);
      setTotalPages(calculatedTotalPages);
      // Calculate current page (ensuring it does not exceed total pages)
      const newPage = Math.min(
        calculatedTotalPages,
        Math.ceil((scrollLeft + containerWidth) / containerWidth)
      );
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    updatePage();
    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', updatePage);
      return () => containerRef.current?.removeEventListener('scroll', updatePage);
    }
  }, [containerRef]);

  // Hide the component if there’s only one page
  if (totalPages <= 1) return null;

  return (
    <Container variant={variant}>
      <IndicatorWrapper>
        <DotsWrapper>
          {Array.from({ length: totalPages }).map((_, index) => (
            <PageIndicator key={index} isActive={index === currentPage - 1} variant={variant} />
          ))}
        </DotsWrapper>
        <PageText variant={variant}>{`${currentPage} of ${totalPages}`}</PageText>
      </IndicatorWrapper>
    </Container>
  );
};

export default SwipeIndicator;
