import React, { useEffect, useRef, useState } from 'react';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import aiFeedback from 'stile-shared/assets/videos/ai_feedback.mp4';
import balanceEquation from 'stile-shared/assets/videos/balance_equations.mp4';
import editQuestion from 'stile-shared/assets/videos/edit_question.mp4';
import lessonVideo from 'stile-shared/assets/videos/lesson_video.mp4';
import maths from 'stile-shared/assets/videos/maths.mp4';
import studentAnswerFeedback from 'stile-shared/assets/videos/student_answer_feedback.mp4';
import writtenResponse from 'stile-shared/assets/videos/written_response.mp4';
import styled from 'styled-components';

import { StyledWITBTopSection } from '../common/Included/WITBTopSection/WITBTopSection.styles';
import { Button } from 'stile-shared/src/components/2024/common/Button';
import VideoGallery from 'stile-shared/src/components/2024/layout/VideoGallery';

gsap.registerPlugin(ScrollTrigger);

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return isMobile;
}

function useHeadingOffset(ref: React.RefObject<HTMLDivElement>, minOffset = 20, maxOffset = 460) {
  const [offset, setOffset] = useState(0);
  const isMobile = useIsMobile(); // use the custom hook

  useEffect(() => {
    function handleScroll() {
      if (!ref.current || isMobile) return;
      const rect = ref.current.getBoundingClientRect();
      const scrolledIntoSection = -rect.top;
      const newOffset = Math.min(Math.max(scrolledIntoSection, minOffset), maxOffset);
      setOffset(newOffset);
    }
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [ref, minOffset, maxOffset, isMobile]);

  return !isMobile ? offset : 0;
}

const ScrollJackedSection = styled.div`
  position: relative;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  p {
    font-size: 21px;
    font-weight: 400;
    line-height: 26px;
    max-width: 768px;
    @media (max-width: 768px) {
      font-size: 19px;
      line-height: 24px;
    }
  }
`;
const Header = styled<typeof StyledWITBTopSection>(StyledWITBTopSection)`
  z-index: 1;
  transition: transform 0.01s linear;
  position: relative;
  max-width: 1160px;
  padding-top: 150px;
  margin-bottom: 100px;
  @media (max-width: 768px) {
    transform: none !important;
    position: static;
    padding: 60px 25px 0;
  }
`;

const ImagesBlock = styled<typeof VideoGallery>(VideoGallery)`
  position: sticky;
  top: 0;
  z-index: 99999999999;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
  overflow: hidden;
  .video-card {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
`;

export default function TechnologySection() {
  const sectionRef = useRef(null);
  const headingOffset = useHeadingOffset(sectionRef);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Change threshold as needed; here we mark any scroll as "scrolled"
      setScrolled(window.pageYOffset > 20);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <ScrollJackedSection ref={sectionRef}>
      <ContentWrapper>
        <Header
          style={{
            transform: `translateY(${headingOffset}px)`,
            // When not scrolled, z-index is high so the button is clickable.
            // Once scrolled, z-index is lowered so the header goes behind the videos.
          }}
        >
          <h1 className="--huge">Powerful, purposeful technology</h1>
          <p>
            We hate technology for technology&apos;s sake, but used well we believe it can give
            teachers superpowers. Our teaching and learning platform has been refined and
            battle-tested for over a decade in thousands of classrooms, and is continually being
            improved with formidable new AI-powered features.
            <br />
            <br />
            It&apos;s secure, private and powerful.
            <br />
            <br />
          </p>

          <Button
            label={<span>Learn more about our platform</span>}
            newTab={false}
            href="/what-is-stile/technology-for-teaching/"
          />
        </Header>
        <ImagesBlock
          items={[
            { type: 'video', url: editQuestion },
            { type: 'video', url: maths },
            { type: 'video', url: writtenResponse },
            { type: 'video', url: aiFeedback },
            { type: 'video', url: studentAnswerFeedback },
            { type: 'video', url: balanceEquation },
            { type: 'video', url: lessonVideo },
          ]}
        />
      </ContentWrapper>
    </ScrollJackedSection>
  );
}
