import React from 'react';

import styled from 'styled-components';

import { Section } from './Section';

type EvidenceCardProps = React.HTMLAttributes<HTMLDivElement> & {
  title: React.ReactElement | React.ReactNode | string;
  copy: React.ReactElement | React.ReactNode | string;
  image: React.ReactNode;
};

const EvidenceCard = function ({ title, copy, image, ...props }: EvidenceCardProps) {
  return (
    <div className={`--evidence-card`} {...props}>
      <div className="--image --left">{image}</div>
      <h4>{title}</h4>
      <div className="--content">{copy}</div>
    </div>
  );
};

const StyledEvidenceCard = styled<typeof EvidenceCard>(EvidenceCard)`
  grid-column: 3 / span 8;
  display: grid;
  padding: 0 0 76px 0;
  grid-template-columns: 170px auto;
  grid-template-rows: min-content min-content;

  @media (max-width: 768px) {
    grid-column: 1 / span 12;
    padding: 0 0 40px 0;
    grid-template-columns: 52px auto;
    grid-template-rows: min-content min-content;
  }

  h4 {
    padding: 0 0 20px 25px;
    margin: 0;
    height: min-content;
    grid-column: 2 / span 1;

    @media (max-width: 768px) {
      height: 52px;
      align-content: center;
      grid-column: 2 / span 1;
      padding: 0 0 0 12px;
    }
  }

  .--content {
    grid-column: 2 / span 1;
    height: min-content;
    padding: 0 0 0 25px;

    p {
      padding: 0;
      margin: 0 0 25px 0;
      @media (max-width: 768px) {
        margin: 0 0 20px 0;
      }
    }

    @media (max-width: 768px) {
      grid-column: 1 / span 2;
      grid-row: 2;
      padding: 20px 0 0 0;
    }
  }

  .--image {
    width: auto;
    height: auto;
    .gatsby-image-wrapper {
      img {
        height: auto !important;
      }
    }
    grid-row: 1 / span 2;
    @media (max-width: 768px) {
      height: 52px;
      width: 52px;
      align-content: center;
      grid-column: 1 / span 1;
    }
    align-content: center;

    &.--left {
      display: flex;
      grid-column: 1 / span 1;
      @media (max-width: 768px) {
        grid-column: 1 / span 1;
        display: flex;
      }
    }
  }
`;

const EvidenceSection = styled<typeof Section>(Section)`
  .content {
    padding-top: 120px !important;
    padding-bottom: calc(150px - 76px);
  }

  @media (max-width: 768px) {
    .content {
      padding-top: 60px !important;
      padding-bottom: 20px !important;
    }
  }

  .grid-layout {
    margin-top: 0 !important;
  }

  h2 {
    margin: 0 0 32px 0 !important;

    @media (max-width: 768px) {
      margin-bottom: 20px !important;
      text-align: left !important;
    }
  }

  .--subheader {
    color: var(--stile-grey-8);
    grid-column: 4 / span 6;
    text-align: center;
    margin-bottom: 100px;
    @media (max-width: 768px) {
      margin-bottom: 40px;
      grid-column: 1 / span 12;
      text-align: left;
      p {
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export { StyledEvidenceCard, EvidenceSection };
