import React from 'react';

const FacebookIcon: React.FC = () => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M100 10C50.293 10 10 50.293 10 100C10 145.117 43.237 182.377 86.545 188.884V123.85H64.279V100.189H86.545V84.448C86.545 58.384 99.244 46.945 120.907 46.945C131.284 46.945 136.765 47.71 139.366 48.061V68.707H124.588C115.39 68.707 112.177 77.428 112.177 87.256V100.189H139.132L135.478 123.85H112.186V189.073C156.115 183.124 190 145.558 190 100C190 50.293 149.707 10 100 10Z"
      fill="#1877F2"
    />
  </svg>
);
export default FacebookIcon;
