// WITBFeatureSection/WITBFeatureSection.tsx
import React from 'react';

import WITBGridContainer from '../WITBGridContainer/WITBGridContainer';
import LearnMore from './LearnMore';
import { StyledWITBFeatureSection } from './WITBFeatureSection.styles';

interface WITBFeatureSectionProps {
  id?: string;
  title?: string;
  learnMoreHref?: string;
  className?: string;
  children: React.ReactNode;
}

const WITBFeatureSection: React.FC<WITBFeatureSectionProps> = ({
  id,
  title,
  learnMoreHref,
  className,
  children,
  ...props
}) => (
  <StyledWITBFeatureSection id={id} className={className}>
    {title && (
      <div
        {...props}
        className={`title-container ${learnMoreHref ? '--has-learn-more' : '--has-no-learn-more'}`}
      >
        <h4>{title}</h4>
        {learnMoreHref && <LearnMore href={learnMoreHref} />}
      </div>
    )}
    <WITBGridContainer>{children}</WITBGridContainer>
    {/* <div class="overlay left"></div>
    <div class="overlay right"></div> */}
  </StyledWITBFeatureSection>
);

export default WITBFeatureSection;
