import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import styled from 'styled-components';

import { Button } from '../../common/Button';
import SwipeIndicator from '../../common/SwipeIndicator/SwipeIndicator';

gsap.registerPlugin(ScrollTrigger);

function useHeadingOffset(ref: React.RefObject<HTMLDivElement>, minOffset = 20, maxOffset = 155) {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    function handleScroll() {
      if (!ref.current) return;
      const rect = ref.current.getBoundingClientRect();
      const scrolledIntoSection = -rect.top;
      const newOffset = Math.min(Math.max(scrolledIntoSection, minOffset), maxOffset);
      setOffset(newOffset);
    }
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [ref, minOffset, maxOffset]);

  return offset;
}

interface ScrollJackedProps {
  mainHeading?: string;
  subHeadings?: string[];
  paragraphs?: string[];
  buttonLabel?: string;
  buttonHref?: string;
  images?: { src: string; alt: string }[];
}

const ScrollJackedWrapper = styled.div`
  background: linear-gradient(180deg, #01e65a 0%, #04ab44 100%);
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const ScrollJackedSection = styled.div`
  position: relative;
  padding-top: 100px;
  h1 {
    margin-top: 0px;
    max-width: 1160px;
    justify-self: center;
    padding-bottom: 80px;
  }
  width: 100%;
  background: #01e65a;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
`;

const PinnedSection = styled.div`
  position: relative;
  width: 100%;
  max-width: 1160px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  gap: 30px;
`;

const SectionContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  gap: 2rem;
  padding: 0 2rem;
  box-sizing: border-box;
  text-align: left;
`;

const LeftColumn = styled.div`
  grid-column: 1 / span 5;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: space-between;
  a {
    margin-top: 20px;
  }
  p {
    padding-right: 16px;
    font-size: 21px;
    font-weight: 400;
    line-height: 26px;
  }
  .active,
  .heading {
    margin: 0 0 15px;
    transition: opacity 0.3s;
  }
  .heading {
    opacity: 0.3;
  }
  .active {
    opacity: 1;
  }
`;

const RightColumn = styled.div`
  grid-column: 6 / span 7;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Images = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  background: white;
  border-radius: 32px;
  box-shadow: 0px 43px 26px 0px rgba(0, 0, 0, 0.04), 0px 19px 19px 0px rgba(0, 0, 0, 0.06),
    0px 5px 11px 0px rgba(0, 0, 0, 0.07);
  overflow: hidden;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.5s;
  }
  img.active {
    opacity: 1;
  }
`;

const MobileSection = styled.div`
  background: linear-gradient(180deg, #01e65a 0%, #04ab44 100%);
  color: white;
  padding: 60px 0px;
  text-align: center;
`;

const MobileTitle = styled.h1`
  margin: 0 0 24px;
  text-align: center;
  padding: 0px 25px;
`;

const MobileParagraphs = styled.div`
  margin-bottom: 43px;
  padding: 0px 25px;
`;

const MobileImageScroller = styled.div`
  display: flex;
  overflow-x: auto;
  margin-top: 24px;
  padding-bottom: 4px;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  .subheading-image-pair {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    width: calc(100% - 50px);
    max-width: 320px;
    border-radius: 16px;
    scroll-snap-align: start;
    transform: scale(0.8);
    opacity: 0.75;
    transition: transform 0.3s;
    h2 {
      margin: 20px 0px 4px;
    }
    p {
      font-weight: 400;
      font-size: 19px;
      line-height: 24px;
    }
    &.active {
      transform: scale(1);
      opacity: 1;
    }
    &:first-child {
      margin-left: 25px;
    }
    &:last-child {
      margin-right: 25px;
    }
  }

  img {
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
  }
`;

export const ScrollJackedComponent: React.FC<ScrollJackedProps> = ({
  mainHeading,
  subHeadings = [],
  paragraphs = [],
  buttonLabel,
  buttonHref,
  images = [],
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const subheadingRefs = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);
  const sectionRef = useRef<HTMLDivElement>(null);
  const headingOffset = useHeadingOffset(sectionRef);

  useLayoutEffect(() => {
    if (isMobile) return;
    const totalSteps = subHeadings.length;
    if (totalSteps === 0) return;

    let ctx: gsap.Context | null = null;

    setTimeout(() => {
      // Increase the scroll distance so that each section stays in view longer.
      const scrollDistance = window.innerHeight * totalSteps;

      ctx = gsap.context(() => {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: containerRef.current,
            start: 'top top',
            end: `+=${scrollDistance}`, // extended scroll range
            pin: true,
            scrub: true,
            snap: {
              snapTo: 1 / (totalSteps - 1),
              duration: 1,
              delay: 0.1,
              ease: 'power1.inOut',
            },
            onUpdate: (self) => {
              const progress = self.progress;
              let step = Math.floor(progress * totalSteps);
              if (step >= totalSteps) step = totalSteps - 1;
              setActive(step);
            },
          },
        });
        tl.to({}, { duration: 1 });
        return () => tl.kill();
      }, containerRef);
    }, 700);

    return () => ctx?.revert();
  }, [subHeadings, isMobile]);

  // Ensure the first heading and image are focused on mount (for desktop)
  useEffect(() => {
    if (!isMobile) {
      setActive(0);
    }
  }, [isMobile]);

  // Update active state for headings and images, and update the paragraph's active index.
  const setActive = (index: number) => {
    setActiveIndex(index);
  };

  // --- Only attach the mobile scroll logic if isMobile is true
  const mobileScrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isMobile) return;

    const scroller = mobileScrollRef.current;
    if (!scroller) return;

    function handleScroll() {
      const scroller = mobileScrollRef.current;
      if (!scroller) return;

      const { scrollLeft, clientWidth } = scroller;
      const center = scrollLeft + clientWidth / 2;

      let minDistance = Infinity;
      let closestIndex = 0;
      subheadingRefs.current.forEach((item, index) => {
        const itemCenter = item.offsetLeft + item.offsetWidth / 2;
        const distance = Math.abs(center - itemCenter);
        if (distance < minDistance) {
          minDistance = distance;
          closestIndex = index;
        }
      });

      setActiveIndex(closestIndex);
    }

    scroller.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      scroller.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile, images]);

  // --- Mobile layout ---
  if (isMobile) {
    return (
      <MobileSection>
        {mainHeading && <MobileTitle className="--huge">{mainHeading}</MobileTitle>}

        <MobileParagraphs>
          {buttonLabel && buttonHref && (
            <Button label={buttonLabel} href={buttonHref} style="white" />
          )}
        </MobileParagraphs>

        <MobileImageScroller ref={mobileScrollRef}>
          {images.map((img, i) => (
            <div
              key={i}
              className={`subheading-image-pair ${i === activeIndex ? 'active' : ''}`}
              ref={(el) => {
                if (el) subheadingRefs.current[i] = el;
              }}
            >
              <img src={img.src} alt={img.alt} />
              {subHeadings[i] && <h2>{subHeadings[i]}</h2>}
              {paragraphs[i] && <p>{paragraphs[i]}</p>}
            </div>
          ))}
        </MobileImageScroller>

        <SwipeIndicator
          variant="secondary"
          containerRef={mobileScrollRef}
          totalItems={images.length}
        />
      </MobileSection>
    );
  }

  // --- Desktop layout ---
  return (
    <ScrollJackedSection ref={sectionRef}>
      <h1
        className="--huge"
        style={{
          transform: `translateY(${headingOffset}px)`,
          transition: 'transform 0.01s linear',
          position: 'relative',
        }}
      >
        {mainHeading}
      </h1>
      <ScrollJackedWrapper ref={containerRef}>
        <PinnedSection>
          <SectionContent>
            <LeftColumn>
              <div>
                {subHeadings.map((text, i) => (
                  <h2 key={i} className={i === activeIndex ? 'active' : 'heading'}>
                    {text}
                  </h2>
                ))}
              </div>
              <div>
                {/* Render a single paragraph whose text updates */}
                <p>{paragraphs[activeIndex]}</p>
                {buttonLabel && buttonHref && (
                  <Button label={buttonLabel} href={buttonHref} style="white" />
                )}
              </div>
            </LeftColumn>
            <RightColumn>
              <Images>
                {images.map((img, i) => (
                  <img
                    key={i}
                    src={img.src}
                    alt={img.alt}
                    className={i === activeIndex ? 'active' : ''}
                  />
                ))}
              </Images>
            </RightColumn>
          </SectionContent>
        </PinnedSection>
      </ScrollJackedWrapper>
    </ScrollJackedSection>
  );
};
