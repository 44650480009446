import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import styled from 'styled-components';

import {
  Simulation,
  scienceDisciplineToSlug,
  simulationNameToSlug,
  subCategoryToHumanReadable,
} from '../../../../../utils/simulations';

import SimMenuTile from 'stile-shared/src/components/2024/common/SimMenuTile/SimMenuTile';

import SwipeIndicator from '../../SwipeIndicator/SwipeIndicator';

interface MoreSimulationsSwiperProps {
  simulations?: Simulation[];
  all?: boolean;
  random?: boolean;
  scienceDisciplines?: string[];
  limit?: number;
  ordered?: boolean;
  heading: string;
}

const SpotlightTitle = styled.h4`
  grid-column: 1 / -3 !important;
  color: var(--stile-grey-8);
  margin: 80px 0 50px;
  padding: 0;
  white-space: nowrap;
  text-align: left;
  text-wrap: wrap;
  @media (max-width: 768px) {
    margin-bottom: 28px;
    margin-top: 22px;
  }
`;

const MoreSimulationsSwiper = styled.div`
  .content {
    flex-direction: row;
  }
  display: grid;
  grid-column: span 12;
  position: relative;
  width: 100%;
  height: auto;
  padding: 0 0 20px;
  margin: 0 auto;
  background-color: var(--stile-grey-1);
  @media (max-width: 768px) {
    max-width: 100vw;
    padding: 38px 0 60px;
    gap: 0;
  }
`;

const SimulationMenuContainer = styled.div`
  z-index: 30;
  display: flex;
  gap: 28px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  padding: 0 calc(((100% - 1160px) / 2) - 28px);
  @media (max-width: 768px) {
    padding: 0 20px;
  }

  a {
    overflow: hidden;
    width: calc(100% / 3);
    max-width: 368px;
    min-width: 300px;
    @media (max-width: 768px) {
      width: 90%;
    }
  }

  & > div {
    scroll-snap-align: start;
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin: 0;
    padding: 0 10px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

// Desktop page indicator and container
const DSI = styled(SwipeIndicator)`
  z-index: 300;
`;

const DSICon = styled.div`
  margin: 100px 0 0 auto;
  right: 0;
  width: auto;
  z-index: 2;

  @media (max-width: 768px) {
    display: none !important;
  }
`;

// Mobile page indicator and container
const MSI = styled(SwipeIndicator)`
  z-index: 600;
`;

const MSICon = styled.div`
  display: none;

  @media (max-width: 768px) {
    position: relative;
    width: auto;
    transform: translateY(0);
    margin: 0 20px;
    background-color: transparent;
    display: block;
  }
`;

type FilterProps = Omit<MoreSimulationsSwiperProps, 'simulations'>;

const filterSimulations = (
  simulations: Simulation[],
  { all, random, scienceDisciplines }: FilterProps,
  limit?: number,
  ordered?: boolean
): Simulation[] => {
  // Helper: Shuffle array for randomisation
  const shuffleArray = <T,>(array: T[]): T[] =>
    array
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

  // Helper: Sort alphabetically by title
  const sortAlphabetically = (array: Simulation[]): Simulation[] =>
    [...array].sort((a, b) => a.name.localeCompare(b.name));

  // If a list of science disciplines is provided, filter by those
  if (scienceDisciplines) {
    // const currentDisciplines = scienceDisciplines.split(',').map((d) => d.trim().toUpperCase());

    const filteredSimulations = simulations.filter((sim) =>
      sim.scienceDisciplines.some((discipline) => scienceDisciplines.includes(discipline))
    );

    const result = ordered
      ? sortAlphabetically(filteredSimulations)
      : random
      ? shuffleArray(filteredSimulations)
      : filteredSimulations;

    return limit ? result.slice(0, limit) : result;
  }

  // If no specific science disciplines are provided and "all" is true, return all simulations
  if (all) {
    const result = ordered
      ? sortAlphabetically(simulations)
      : random
      ? shuffleArray(simulations)
      : simulations;
    return limit ? result.slice(0, limit) : result;
  }

  // Default: Return the simulations with ordering or randomisation applied
  const result = ordered
    ? sortAlphabetically(simulations)
    : random
    ? shuffleArray(simulations)
    : simulations;
  return limit ? result.slice(0, limit) : result;
};

const MoreSimulationsSwiperComponent = forwardRef<HTMLDivElement, MoreSimulationsSwiperProps>(
  (props, ref) => {
    const localRef = useRef<HTMLDivElement>(null!);
    const { simulations = [], limit, ordered, heading, ...filterProps } = props;

    useImperativeHandle(ref, () => localRef.current);

    const filteredSimulations = filterSimulations(
      simulations,
      { ...filterProps } as FilterProps,
      limit,
      ordered
    );

    return (
      <MoreSimulationsSwiper>
        <div className="content">
          <SpotlightTitle>{heading}</SpotlightTitle>
          <DSICon>
            <DSI containerRef={localRef} totalItems={simulations.length} />
          </DSICon>
        </div>
        <SimulationMenuContainer ref={localRef}>
          {filteredSimulations.map((sim) => {
            const plainDescription = sim.shortDescriptionHtml.replace(/<[^>]*>/g, '');
            const categorySlug = scienceDisciplineToSlug(sim.scienceDisciplines[0]);
            return (
              <SimMenuTile
                key={sim.name}
                imgSrc={sim.coverImageUrl}
                title={sim.name}
                description={plainDescription}
                category={subCategoryToHumanReadable(sim.subCategory)}
                url={`/simulations/${categorySlug}/${simulationNameToSlug(sim.name)}`}
              />
            );
          })}
        </SimulationMenuContainer>
        <MSICon>
          <MSI containerRef={localRef} totalItems={simulations.length} />
        </MSICon>
      </MoreSimulationsSwiper>
    );
  }
);

export default MoreSimulationsSwiperComponent;

MoreSimulationsSwiperComponent.displayName = 'MoreSimulationsSwiperComponent';
