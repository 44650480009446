import React, { useEffect, useRef, useState } from 'react';

import ReactPlayer from 'react-player';

interface VideoPlayerProps {
  url: string;
  playing?: boolean;
  loop?: boolean;
  muted?: boolean;
  controls?: boolean;
  width?: string;
  height?: string;
  borderRadius?: string;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  url,
  playing = true,
  loop = true,
  muted = true,
  controls = false,
  width = '100%',
  height = '100%',
  borderRadius = '32px',
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(playing);
  const videoRef = useRef<ReactPlayer | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null); // Ref for the wrapper div

  useEffect(() => {
    // Only apply IntersectionObserver logic when loop is false
    if (!loop) {
      const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsPlaying(true); // Play the video when it enters the viewport
          } else {
            setIsPlaying(false); // Stop the video when it exits the viewport
            if (videoRef.current) {
              videoRef.current.seekTo(0); // Reset the video to the start
            }
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: '0px',
        threshold: 0.9, // 90% of the video must be visible
      });

      if (containerRef.current) {
        observer.observe(containerRef.current); // Observe the wrapper div
      }

      return () => {
        if (containerRef.current) {
          observer.unobserve(containerRef.current);
        }
      };
    }
  }, [loop]);

  return (
    <div
      className="video"
      ref={containerRef} // Attach the ref here
      style={{
        borderRadius,
        overflow: 'hidden',
      }}
    >
      <ReactPlayer
        ref={videoRef}
        url={url}
        playing={isPlaying}
        loop={loop}
        muted={muted}
        controls={controls}
        width={width}
        height={height}
        playsinline
        onEnded={() => {
          if (!loop) {
            setIsPlaying(false); // Stop the video when it ends and don't loop it
          }
        }}
      />
    </div>
  );
};
