import React, { useEffect, useState } from 'react';

import useWindowSize from 'react-use/lib/useWindowSize';
import iconShare from 'stile-shared/assets/icons/icon_share.svg';
import styled from 'styled-components';

import { simulationNameToSlug, subCategoryToHumanReadable } from '../../../../../utils/simulations';
import { Simulation } from '../../../../../utils/simulations';

import { Section } from 'stile-shared/src/components/2024/layout/Section';

import { Button } from '../../Button';
import { Icon } from '../../Icon';
import { Link } from '../../Link';
import SocialShare from '../../SocialShare/SocialShare';
import { Modal } from '../SimulationLauncher/SimulationLauncher';

const HeaderSection = styled(Section)`
  display: inherit;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
  background-color: transparent;
  margin: 0 0 !important;
  padding: 0 var(--grid-gap);
  max-width: var(--max-content-width);
  .content {
    margin: 0 0 !important;
  }
  @media (max-width: 768px) {
    grid-column: 1 / -1;
    row-gap: 16px;
  }
`;

const HeaderLeft = styled.div`
  grid-column: span 5;
  gap: 20px;

  .type {
    font-weight: 600;
    margin-bottom: 16px !important;
    margin-top: 16px !important;
  }

  @media (max-width: 768px) {
    grid-column: span 12;
    gap: 15px;
  }
`;

const PageHeadingDesk = styled.h2`
  grid-column: span 12;
  text-align: left !important;
  left: 0;
  margin: 0;
`;

const PageHeadingMob = styled.h1`
  display: grid;
  grid-column: span 12;
  text-align: left !important;
  width: 100%;
  left: 0;
  margin: 0;
`;

interface PageHeadingProps {
  children: React.ReactNode;
}

const PageHeading: React.FC<PageHeadingProps> = ({ children }) => {
  const size = useWindowSize();
  const isMobile = size.width <= 768;
  return isMobile ? (
    <PageHeadingMob>{children}</PageHeadingMob>
  ) : (
    <PageHeadingDesk>{children}</PageHeadingDesk>
  );
};

const Overview = styled.p`
  grid-column: span 6;
  font-size: 16px;
  line-height: 1.5;
  width: 465px;
  margin: 0;
  padding: 0;
  p {
    margin: 16px 0 16px !important;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start;
  margin-top: 16px !important;
`;

const CategoryLabel = styled.p`
  color: var(--stile-grey-6);
  font-size: 14px;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const HeaderRight = styled.div`
  grid-column: span 7; /* Desktop: occupies 6/12 columns */
  display: flex;
  justify-content: right;
  align-items: center;

  @media (max-width: 768px) {
    grid-column: span 12;
    margin-top: 20px;
  }
`;

const InteractiveSimulation = styled.div`
  width: 566px;
  height: 320px;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.07), 0px 19px 19px 0px rgba(0, 0, 0, 0.06),
    0px 43px 26px 0px rgba(0, 0, 0, 0.04), 0px 80px 31px 0px rgba(0, 0, 0, 0.02);

  @media (max-width: 768px) {
    width: 100%;
    height: 12em;
    align-self: center;
    margin: 15px 0 0;
  }
`;

const Biopic = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
  position: absolute;
  z-index: 1;
`;

const SimCover = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;

const LaunchButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 188px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  background-color: var(--stile-green-1);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  z-index: 3;
  font-size: 14px;
  font-weight: 600;
  color: var(--stile-grey-8);
  text-align: center;

  &:hover {
    background-color: #00d655;
  }

  &:focus {
    outline: 2px solid var(--stile-green-3);
  }

  svg {
    width: 18px;
    height: 18px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
const BreadCrumbs = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 32px;
  margin-top: 40px;

  .separator {
    margin: 0 6px;
    color: var(--stile-grey-5);
  }

  a {
    color: var(--stile-grey-8);
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover {
      color: var(--stile-green-3);
    }
  }
  .current {
    color: var(--stile-grey-5);
  }
  @media (max-width: 768px) {
    margin-top: 17px;
    margin-bottom: 0px;
  }
`;

const Overlay = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2); /* Dark overlay */
  z-index: 999998;
`;

const PlayIcon: React.FC = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.795 12.8999L12.8999 9L6.795 5.10007V12.8999ZM9 18C7.76505 18 6.60008 17.7638 5.50508 17.2913C4.41008 16.8188 3.45503 16.175 2.63993 15.3601C1.82498 14.545 1.18125 13.5899 0.70875 12.4949C0.23625 11.3999 0 10.235 0 9C0 7.755 0.23625 6.585 0.70875 5.49C1.18125 4.395 1.82498 3.4425 2.63993 2.6325C3.45503 1.8225 4.41008 1.18125 5.50508 0.70875C6.60008 0.23625 7.76505 0 9 0C10.245 0 11.415 0.23625 12.51 0.70875C13.605 1.18125 14.5575 1.8225 15.3675 2.6325C16.1775 3.4425 16.8188 4.395 17.2913 5.49C17.7638 6.585 18 7.755 18 9C18 10.235 17.7638 11.3999 17.2913 12.4949C16.8188 13.5899 16.1775 14.545 15.3675 15.3601C14.5575 16.175 13.605 16.8188 12.51 17.2913C11.415 17.7638 10.245 18 9 18Z"
      fill="var(--stile-grey-8)"
    />
  </svg>
);

interface Props {
  simulation: Simulation;
  categoryName: string;
  categorySlug: string;
}

const SimulationHeader: React.FC<Props> = ({ simulation, categoryName, categorySlug }) => {
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    updateIsMobile();

    // Listen for window resize
    window.addEventListener('resize', updateIsMobile);

    // Cleanup listener on unmount
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);

  const url = simulation.shortLink
    ? `stileapp.com/go/${simulation.shortLink}`
    : window.location.href;
  return (
    <>
      <div className="content">
        <BreadCrumbs>
          <Link url="/simulations">Simulations and Interactives </Link>
          <span className="separator">&gt;</span>
          <Link url={`/simulations/${categorySlug}`}>{categoryName}</Link>
          {isMobile ? null : (
            <>
              <span className="separator">&gt;</span>
              <Link
                className="current"
                url={`/simulations/${categorySlug}/${simulationNameToSlug(simulation.name)}`}
              >
                {simulation.name}
              </Link>
            </>
          )}
        </BreadCrumbs>

        <HeaderSection>
          <HeaderLeft>
            <PageHeading>{simulation.name}</PageHeading>
            <p className="type">{simulation.simulationType}</p>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: simulation.longDescriptionHtml }}
            />
            <InfoContainer>
              {/* <Overview dangerouslySetInnerHTML={{ __html: simulation.shortDescriptionHtml }} />
              {simulation.subCategory && (
                <CategoryLabel>{subCategoryToHumanReadable(simulation.subCategory)}</CategoryLabel>
              )} */}
              <Button
                label={
                  <>
                    <span>Share</span>
                    <Icon src={iconShare} iconSize="20px" style={{ marginLeft: 6 }} />
                  </>
                }
                style="secondary"
                onClick={() => setIsShareOpen(!isShareOpen)}
              />
              {isShareOpen && (
                <>
                  <Overlay isVisible={isShareOpen} onClick={() => setIsShareOpen(false)} />
                  <SocialShare url={url} />
                </>
              )}
            </InfoContainer>
          </HeaderLeft>
          <HeaderRight>
            <InteractiveSimulation>
              <Biopic src={simulation.coverImageUrl} alt={simulation.name} />
              <SimCover />
              <LaunchButton
                onClick={openModal}
                aria-label={`Launch simulation: ${simulation.name}`}
              >
                Launch {simulation.simulationType}
                {/* Simulation or interactive this will be dependant on the sim */}
                <PlayIcon />
              </LaunchButton>
            </InteractiveSimulation>
          </HeaderRight>
        </HeaderSection>
      </div>
      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        label={`Launch simulation: ${simulation.name}`}
      >
        {/* The container is full-screen, so just let the iframe be 100% */}
        <div style={{ width: '100%', height: '100%' }}>
          <iframe
            src={simulation.uri}
            title={simulation.name}
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
            }}
          />
        </div>
      </Modal>
    </>
  );
};
export default SimulationHeader;
