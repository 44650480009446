// src/pages/simulations.tsx
import React, { useRef } from 'react';

import styled from 'styled-components';

import {
  Grade,
  Simulation,
  gradeOrder,
  gradeToHumanReadable,
  gradeToSlug,
} from '../../../../utils/simulations';

import TitleMenu from 'stile-shared/src/components/2024/common/Included/WITBTitleMenu/WITBTitleMenu';
import Top from 'stile-shared/src/components/2024/common/Included/WITBTopSection/WITBTopSection';
import { TagMenu } from 'stile-shared/src/components/2024/common/TagMenu/TagMenu';

import SimulationCategoryComponent from './SimulationCategoryComponent';

const TitleMenuContainer = styled.div`
  padding: 0 80px 80px;
  @media (max-width: 768px) {
    padding: 0 25px 60px;
  }
`;
const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 0px 140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .--small {
    color: var(--Primary-Stile-Grey-5, #928d88);
    padding-bottom: 30px;
  }
  @media (max-width: 768px) {
    padding: 40px 25px;
    .--small {
      padding-bottom: 0px;
    }
  }
`;
const GradeTags = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 20px 0 10px 0;
  gap: 8px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    flex-shrink: 0;
    padding: 0;
  }
  &:hover {
    padding-left: 0;
  }
  @media (max-width: 768px) {
    margin: 0;
    justify-content: flex-start;

    > :first-child {
      margin-left: 0;
    }

    > :last-child {
      margin-right: 0;
    }
  }
`;

type SimulationsIndexPagePageProps = {
  allSimulations: Simulation[];
  region: 'au' | 'us';
};

function SimulationsIndexPage({ allSimulations, region }: SimulationsIndexPagePageProps) {
  // console.log('SimulationsIndexPage allSimulations', allSimulations);

  const grades: Grade[] = Array.from(new Set(allSimulations.flatMap((sim) => sim.grade ?? [])));

  const sortedGrades = grades.sort((a, b) => gradeOrder[a] - gradeOrder[b]);

  const gradelinkData = sortedGrades.map((g) => ({
    filterValue: g,
    filterDisplay: gradeToHumanReadable(g, region),
    url: gradeToSlug(g, region),
  }));

  return (
    <>
      <TitleMenuContainer>
        <TitleMenu>
          <Top
            title="Simulations & Interactives"
            description={
              region === 'au'
                ? "Simulations are science experiments where students test cause-and-effect in a virtual world. Best part? They're free and built into lessons for hands-on learning. Explore below!"
                : "Stile’s custom-built NGSS simulations for middle and high school are now free to use. Each one is deliberately designed to unpack a singular scientific concept all while putting students in the driver’s seat. Explore the library below and share more 'aha' moments with your students."
            }
          />
        </TitleMenu>
        <GradeTags>
          {gradelinkData.map((grade) => (
            <TagMenu
              key={grade.filterValue}
              type="link"
              filterValue={grade.filterValue}
              filterDisplay={grade.filterDisplay}
              url={grade.url}
            />
          ))}
        </GradeTags>
      </TitleMenuContainer>
      <Container>
        <SimulationCategoryComponent
          simulations={allSimulations.filter((s) => s.scienceDisciplines?.[0] === 'PHYSICS')}
          categorySlug={'physics'}
          title="Physics"
        />
        <SimulationCategoryComponent
          simulations={allSimulations.filter((s) => s.scienceDisciplines?.[0] === 'CHEMISTRY')}
          categorySlug={'chemistry'}
          title="Chemistry"
        />
        <SimulationCategoryComponent
          simulations={allSimulations.filter((s) => s.scienceDisciplines?.[0] === 'BIOLOGY')}
          categorySlug={'biology'}
          title="Biology"
        />
        <SimulationCategoryComponent
          simulations={allSimulations.filter((s) => s.scienceDisciplines?.[0] === 'EARTH_SCIENCE')}
          categorySlug={'earth-science'}
          title="Earth & Space Science"
        />
        <SimulationCategoryComponent
          simulations={allSimulations.filter((s) => s.scienceDisciplines?.[0] === 'MATH')}
          categorySlug={'math'}
          title="Math"
        />
        <div className="--small">
          Science and maths should be active, immersive, and engaging—and that’s exactly what
          Stile’s interactive simulations deliver. Covering Physics, Chemistry, Biology,
          Mathematics, and Earth & Space Science, students can explore Mechanics, Thermodynamics,
          Genetics, Algebra, Astronomy, and more through hands-on, dynamic learning experiences.{' '}
          <br />
          Each simulation and interactive is built around clear learning objectives, providing
          interactive labs for hands-on exploration, real-world applications that connect theory to
          practice, and quizzes with practice problems to reinforce learning.
          <br /> Designed for elementary, middle, and high school students, Stile simulations fit
          seamlessly into whole-class instruction, small group activities, homework, remote
          learning, and enrichment programs. Built to meet the highest educational standards, Stile
          simulations align with NGSS, Common Core, AP Science, IB Science, and STEM
          pathways—ensuring seamless integration into your curriculum while enhancing student
          outcomes.
        </div>
      </Container>
    </>
  );
}
export default SimulationsIndexPage;
