import React from 'react';

import { HeadProps, PageProps, graphql } from 'gatsby';
import styled from 'styled-components';

import { Link } from 'stile-shared/src/components/2024/common/Link/Link';
import SimulationCategoryComponent from 'stile-shared/src/components/2024/common/Simulations/SimulationCategoryComponent';
import {
  Grade,
  ScienceDiscipline,
  Simulation,
  SubCategory,
  gradeToHumanReadable,
  gradeToSlug,
  scienceDisciplineToHumanReadable,
  scienceDisciplineToSlug,
  subCategoryToHumanReadable,
} from 'stile-shared/src/utils/simulations';

import { getPageData } from 'utils/getPageData';

import { PageLayout } from 'templates/2024/PageLayout';
import { SEO } from 'templates/2024/SEO';

const BreadCrumbs = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 32px;
  margin-top: 40px;

  .separator {
    margin: 0 6px;
    color: var(--stile-grey-5);
  }

  a {
    color: var(--stile-grey-8);
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover {
      color: var(--stile-green-3);
    }
  }
  .current {
    color: var(--stile-grey-5);
  }
  @media (max-width: 768px) {
    margin-top: 17px;
    margin-bottom: 0px;
  }
`;
const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 20px 140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;

  @media (max-width: 768px) {
    padding: 32px 20px;
    gap: 60px;
  }
`;

const HeadingSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  margin: 0;
  padding: 0;

  h1 {
    max-width: 700px;
    font-size: 55px;
    text-align: center;
    margin: 0;
    padding: 0;

    @media (max-width: 768px) {
      width: calc(100vw - 50px);
      font-size: 38px;
    }
  }

  p {
    max-width: 563px;
    text-align: center;
    margin: 0;
    padding: 0;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
export function Head(props: HeadProps) {
  const { grade, subCategory, scienceDiscipline } = props.pageContext as {
    grade?: Grade;
    subCategory?: SubCategory;
    scienceDiscipline?: ScienceDiscipline;
  };

  const { seo } = getPageData('/simulations/');

  let categoryName = '';
  if (grade) {
    categoryName += gradeToHumanReadable(grade, 'us') + ' ';
  }

  if (subCategory) {
    categoryName += subCategoryToHumanReadable(subCategory) + ' ';
  } else if (scienceDiscipline) {
    categoryName += scienceDisciplineToHumanReadable(scienceDiscipline) + ' ';
  }

  const ourTitle = `${categoryName} - ${seo.title}`;
  const ourDescription = `${categoryName} - ${seo.description}`;

  return (
    <SEO
      slug={`/simulations/`}
      description={ourDescription}
      title={ourTitle}
      opengraphTitle={seo.description}
      opengraphDescription={ourDescription}
    />
  );
}

type SimulationCategoryPageProps = PageProps & {
  pageContext: {
    grade?: Grade;
    subCategory?: SubCategory;
    scienceDiscipline?: ScienceDiscipline;
    categoryBlurb: string;
  };
  data: Queries.allSimulationsUsQuery;
};

function SimulationCategoryPage({ data, pageContext }: SimulationCategoryPageProps) {
  const { grade, subCategory, scienceDiscipline, categoryBlurb } = pageContext;

  // Compute the category name and slug based on page context
  let categoryName = '';
  const categories = [];
  if (grade) {
    categoryName += gradeToHumanReadable(grade, 'us') + ' ';
    categories.push(gradeToSlug(grade, 'us'));
  } else if (scienceDiscipline) {
    categoryName += scienceDisciplineToHumanReadable(scienceDiscipline) + ' ';
    categories.push(scienceDisciplineToSlug(scienceDiscipline));
  }
  const categorySlug = categories.join('/');

  const allSimulations = data.allSimulationsUs.nodes[0].simulations as Simulation[];

  const filteredSimulations = allSimulations
    .filter((s) => !grade || (s.grade ?? []).indexOf(grade) >= 0)
    .filter((s) => !scienceDiscipline || s.scienceDisciplines?.indexOf(scienceDiscipline) >= 0)
    .filter((s) => !subCategory || s.subCategory === subCategory);

  return (
    <PageLayout>
      <div className="content">
        <BreadCrumbs>
          <Link url="/simulations">Simulations and Interactives </Link>
          <span className="separator">&gt;</span>
          <Link url={`/simulations/${categorySlug}`}>{categoryName.trim()}</Link>
        </BreadCrumbs>
      </div>
      <Container>
        <HeadingSection>
          <h1>{categoryName.trim()} Simulations & Interactives</h1>
          <p>{categoryBlurb}</p>
        </HeadingSection>
        <SimulationCategoryComponent
          simulations={filteredSimulations}
          categorySlug={categorySlug}
          title={''}
        />
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query allSimulationsUs {
    allSimulationsUs {
      nodes {
        simulations {
          name
          uri
          topicsHtml
          shortDescriptionHtml
          subCategory
          scienceDisciplines
          learningObjectivesHtml
          longDescriptionHtml
          instructionsHtml
          simulationType
          grade
          coverImageUrl
          shortLink
          outcomesByCurriculumCode {
            US_OR {
              learningOutcomes {
                learningOutcomeDescription
                learningOutcomeCode
              }
            }
            US_NC {
              learningOutcomes {
                learningOutcomeDescription
                learningOutcomeCode
              }
            }
            AU_V9 {
              learningOutcomes {
                learningOutcomeDescription
                learningOutcomeCode
              }
            }
            AU_NSW_2023 {
              learningOutcomes {
                learningOutcomeDescription
                learningOutcomeCode
              }
            }
            US_HS {
              learningOutcomes {
                learningOutcomeDescription
                learningOutcomeCode
              }
            }
            US {
              learningOutcomes {
                learningOutcomeDescription
                learningOutcomeCode
              }
            }
          }
        }
      }
    }
  }
`;

export default SimulationCategoryPage;
