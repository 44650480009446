import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

// Styled Components
const ToastContainer = styled.div<{ fadingOut: boolean }>`
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  min-height: 45px;
  max-width: 320px;
  min-width: 276px;
  padding: 12px;
  background: var(--stile-grey-8);
  border-radius: 8px;
  position: fixed;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  opacity: 1;
  transition: opacity 500ms ease-out, transform 500ms ease-out;

  /* Triple-drop shadow */
  box-shadow: 
    0px 5px 11px 0px rgba(0, 0, 0, 0.07),
    0px 19px 19px 0px rgba(0, 0, 0, 0.06),
    0px 43px 26px 0px rgba(0, 0, 0, 0.04);

  ${({ fadingOut }) =>
    fadingOut &&
    css`
      opacity: 0;
      transform: translateX(-50%) translateY(-20px) scale(85%); /* Slide slightly upward while fading out */
    `}
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ToastMessage = styled.div`
  font-size: 14px;
  font-family: Arial, sans-serif;
  font-weight: 400;
  color: white;
`;

interface ToastProps {
  message: string;
  onClose: () => void; // Callback for closing the toast
}

const Toast: React.FC<ToastProps> = ({ message, onClose }) => {
  const [fadingOut, setFadingOut] = useState(false);

  // Handle auto-dismiss after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => triggerFadeOut(), 5000);
    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  // Function to trigger fade-out animation
  const triggerFadeOut = () => {
    setFadingOut(true);
    setTimeout(onClose, 500); // Close after animation completes
  };

  return (
    <ToastContainer fadingOut={fadingOut}>
      {/* Left Icon */}
      <IconWrapper>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5ZM10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
            fill="#00E65A"
          />
          <path
            d="M8.57085 13.4999L5.24585 10.1749L6.0771 9.34369L8.57085 11.8374L13.9229 6.48535L14.7542 7.3166L8.57085 13.4999Z"
            fill="#00E65A"
          />
        </svg>
      </IconWrapper>

      {/* Message */}
      <ToastMessage>{message}</ToastMessage>

      {/* Right Close Icon */}
      <CloseWrapper onClick={triggerFadeOut}>
        <svg
          width="15"
          height="20"
          viewBox="0 0 15 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 5.20857L12.7914 4L8 8.79143L3.20857 4L2 5.20857L6.79143 10L2 14.7914L3.20857 16L8 11.2086L12.7914 16L14 14.7914L9.20857 10L14 5.20857Z"
            fill="#B5B0A9"
          />
        </svg>
      </CloseWrapper>
    </ToastContainer>
  );
};

export default Toast;