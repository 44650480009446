import React from 'react';

import styled from 'styled-components';

import {
  Simulation,
  simulationNameToSlug,
  subCategoryToHumanReadable,
} from '../../../../utils/simulations';

import SimMenuTile from 'stile-shared/src/components/2024/common/SimMenuTile/SimMenuTile';
import { Section } from 'stile-shared/src/components/2024/layout/Section';

const SimulationsContainer = styled.div`
  width: 100%;
  gap: 0;
`;

const TitleRow = styled.div`
  display: flex;
  height: 47px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1145px;
  padding: 0;
  margin: 0;

  h4 {
    margin: 0;
    @media (max-width: 768px) {
      font-size: 34px;
      line-height: 35px;
    }
  }

  span {
    margin: 0 0;
  }
`;

const SimulationGrid = styled(Section)`
  flex-shrink: 0;
  gap: 300px 28px;
  margin: 0 auto !important;
  padding: 0% !important;

  .content {
    padding-top: 0 !important;
    margin: 0 !important;
    width: auto !important;
  }

  @media (max-width: 1000px) {
    gap: 20px;
  }

  @media (max-width: 768px) {
    gap: 16px;
  }
  > * {
    flex-shrink: 0;
    width: 100%;
    padding: 0;
    margin: 0;
  }
`;

type SimulationCategoryComponentProps = {
  simulations: Simulation[];
  categorySlug: string;
  title: string;
};

const SimulationCategoryComponent: React.FC<SimulationCategoryComponentProps> = ({
  simulations,
  categorySlug,
  title,
}) => {
  return (
    <SimulationsContainer>
      <TitleRow>
        <h4>{title}</h4>
        <span>{simulations.length} items</span>
      </TitleRow>
      <SimulationGrid>
        {simulations.map((sim) => {
          const plainDescription = sim.shortDescriptionHtml.replace(/<[^>]*>/g, '');
          return (
            <SimMenuTile
              key={sim.name}
              imgSrc={sim.coverImageUrl}
              title={sim.name}
              description={plainDescription}
              category={subCategoryToHumanReadable(sim.subCategory)}
              url={`/simulations/${categorySlug}/${simulationNameToSlug(sim.name)}`}
            />
          );
        })}
      </SimulationGrid>
    </SimulationsContainer>
  );
};

export default SimulationCategoryComponent;
