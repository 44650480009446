import React from 'react';

import {
  ScienceDiscipline,
  Simulation,
  scienceDisciplineToHumanReadable,
} from 'stile-shared/src/utils/simulations';
import styled from 'styled-components';

import { Section } from 'stile-shared/src/components/2024/layout/Section';

import MoreSimulationsSwiper from './MoreSimulationsSwiper/MoreSimulationsSwiper';
import SimulationHeader from './SimulationHeader/SimulationHeader';
import SimulationDetails from './SimulationInstructions/Details/Details';
import OrderedInstructions from './SimulationInstructions/OrderedList/OrderedList';

const SpotSwiper = styled(MoreSimulationsSwiper)`
  grid-column: 1; /* Place the component in the first column */
  justify-self: start; /* Align it to the start of its grid cell */
  margin-top: var(--spacing-md); /* Add dynamic spacing instead of fixed transform */
  z-index: 50;
  box-sizing: border-box;
`;

interface Props {
  simulation: Simulation;
  allSimulations: Simulation[];
  region: 'au' | 'us'; // Added region prop
  categoryName: string;
  categorySlug: string;
}

const SimulationDetailsComponent: React.FC<Props> = ({
  simulation,
  allSimulations,
  region,
  categoryName,
  categorySlug,
}) => {
  if (!simulation) return <p>Simulation not found</p>;

  const relatedSimulations = allSimulations.filter((sim) => sim.name !== simulation.name);

  return (
    <>
      {/* Header */}
      <SimulationHeader
        simulation={simulation}
        categoryName={categoryName}
        categorySlug={categorySlug}
      />

      {/* Instructions */}
      <OrderedInstructions simulation={simulation} />

      {/* Details - Pass region prop */}
      <SimulationDetails simulation={simulation} region={region} />

      {/* Related Simulations */}
      <SpotSwiper
        heading={`More ${simulation.scienceDisciplines
          .map((sd: ScienceDiscipline) => scienceDisciplineToHumanReadable(sd))
          .join(' and ')} 
          simulations`}
        simulations={relatedSimulations}
        scienceDisciplines={simulation.scienceDisciplines}
        all={false}
        limit={15}
      />
    </>
  );
};

export default SimulationDetailsComponent;
