import React from 'react';

import styled from 'styled-components';

import { Simulation } from '../../../../../../utils/simulations';

import { Section } from 'stile-shared/src/components/2024/layout/Section';

const Instructions = styled(Section)`
  background-color: transparent !important;
  grid-column: 1 / -1 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin: 0;
  gap: 20px;
  & .content {
    padding-top: 42px !important;
  }
  @media (max-width: 768px) {
    padding: 0;
    & .content {
      padding-top: 22px !important;
    }
  }
`;

const InstructionContainer = styled.div`
  grid-column: 1 / -1;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  h4 {
    margin: 40px 0 8px;
  }

  h6 {
    margin: 0;
  }
  p {
    margin-top: 0;
    margin-bottom: 16px !important;
  }
  @media (max-width: 768px) {
    h4 {
      margin: 22px 0 0 0;
    }
  }
`;

const InstructionListContainer = styled.div`
  width: 100%; /* Ensures the list spans the container */
  text-align: left;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: left !important;
    margin-top: 80px !important;
    font-size: 40px !important;
    font-weight: 500 !important;
    line-height: 45px !important;
    @media (max-width: 768px) {
      margin-top: 60px !important;
      font-size: 26px !important;
      line-height: 28px !important;
    }
  }
`;
interface Props {
  simulation: Simulation;
}

const OrderedInstructions: React.FC<Props> = ({ simulation }) => {
  return (
    <Instructions>
      <InstructionContainer>
        <h4>Instructions</h4>
        <InstructionListContainer>
          <div dangerouslySetInnerHTML={{ __html: simulation.instructionsHtml }} />
        </InstructionListContainer>
      </InstructionContainer>
    </Instructions>
  );
};

export default OrderedInstructions;
