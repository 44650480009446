import React from 'react';

import type { HeadProps, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import YouTubePlayer from 'react-player/youtube';
import styled from 'styled-components';

import iconPlay from 'stile-shared/assets/icons/icon_play.svg';
import academy from 'stile-shared/assets/images/homepage/academy.png';
import alex from 'stile-shared/assets/images/homepage/alex.jpg';
import filming from 'stile-shared/assets/images/homepage/byron_filming.jpg';
import workbooks from 'stile-shared/assets/images/homepage/byron_workbooks.jpg';
import cham from 'stile-shared/assets/images/homepage/cham.jpg';
import discussions from 'stile-shared/assets/images/homepage/discussions_debates.png';
import engineering from 'stile-shared/assets/images/homepage/engineering_challenges.jpg';
import execs from 'stile-shared/assets/images/homepage/execs.jpg';
import footerBackground from 'stile-shared/assets/images/homepage/homepage_footer_background.svg';
import footerBackgroundMobile from 'stile-shared/assets/images/homepage/homepage_footer_background_mobile.png';
import testimonialBackground from 'stile-shared/assets/images/homepage/homepage_testimonial_background.svg';
import testimonialBackgroundMobile from 'stile-shared/assets/images/homepage/homepage_testimonial_background_mobile.svg';
import titleBackground from 'stile-shared/assets/images/homepage/homepage_title_background.svg';
import titleBackgroundMobile from 'stile-shared/assets/images/homepage/homepage_title_background_mobile.png';
import jacky from 'stile-shared/assets/images/homepage/jacky.jpg';
import kat from 'stile-shared/assets/images/homepage/kat.jpg';
import mark from 'stile-shared/assets/images/homepage/mark.jpg';
import mel from 'stile-shared/assets/images/homepage/mel.jpg';
import podcast from 'stile-shared/assets/images/homepage/podcast.jpg';
import robotbee from 'stile-shared/assets/images/homepage/robotbee.svg';
import labs from 'stile-shared/assets/images/homepage/robust_labs.png';
import sandpit from 'stile-shared/assets/images/homepage/sandpit.jpg';
import school from 'stile-shared/assets/images/homepage/school_filming.jpg';
import sean from 'stile-shared/assets/images/homepage/sean.jpg';
import stars from 'stile-shared/assets/images/homepage/stars.svg';
import stickyNotes from 'stile-shared/assets/images/homepage/sticky_notes.jpg';
import tShirt from 'stile-shared/assets/images/homepage/t-shirt.jpg';
import tim from 'stile-shared/assets/images/homepage/tim.jpg';
import training from 'stile-shared/assets/images/homepage/training.png';
import underlineMobile from 'stile-shared/assets/images/homepage/underline-mobile.svg';
import underline from 'stile-shared/assets/images/homepage/underline.svg';
import workshops from 'stile-shared/assets/images/homepage/workshops.png';
import curriculum from 'stile-shared/assets/videos/curriculum.mp4';
import homepage from 'stile-shared/assets/videos/homepage_US.mp4';
import lessons from 'stile-shared/assets/videos/lessons.mp4';
import supportTeachers from 'stile-shared/assets/videos/support_teachers.mp4';
import updatedData from 'stile-shared/assets/videos/updated_data.mp4';

import { Button } from 'stile-shared/src/components/2024/common/Button';
import Hearts from 'stile-shared/src/components/2024/common/Hearts';
import { Icon } from 'stile-shared/src/components/2024/common/Icon';
import ImageCarousel from 'stile-shared/src/components/2024/common/ImageCarousel';
import WITBFeatureSection from 'stile-shared/src/components/2024/common/Included/WITBFeatureSection/WITBFeatureSection';
import WITBGridItem from 'stile-shared/src/components/2024/common/Included/WITBGridItem/WITBGridItem';
import WITBGridRow from 'stile-shared/src/components/2024/common/Included/WITBGridRow/WITBGridRow';
import TitleMenu from 'stile-shared/src/components/2024/common/Included/WITBTitleMenu/WITBTitleMenu';
import Top from 'stile-shared/src/components/2024/common/Included/WITBTopSection/WITBTopSection';
import ScrollIndicatorContainer from 'stile-shared/src/components/2024/common/ScrollIndicatorContainer/ScrollIndicatorContainer';
import { VideoPlayer } from 'stile-shared/src/components/2024/common/VideoPlayer';
import { EvidenceSection } from 'stile-shared/src/components/2024/layout/CardSection';
import ReviewsSlider from 'stile-shared/src/components/2024/layout/ReviewsSlider/ReviewsSlider';
import { ScrollJackedComponent } from 'stile-shared/src/components/2024/layout/ScrollJackedSection/ScrollJackedComponent';
import ScrollingSection from 'stile-shared/src/components/2024/layout/ScrollingSection/ScrollingSection';
import { Section } from 'stile-shared/src/components/2024/layout/Section';
import TechnologySection from 'stile-shared/src/components/2024/layout/TechnologySection';

import { PageLayout } from 'templates/2024/PageLayout';
import { SEO } from 'templates/2024/SEO';

const HomePageLayout = styled(PageLayout)`
  .header-wrapper {
    background-color: var(--white) !important;
    box-shadow: 0px 78px 22px 0px rgba(0, 0, 0, 0), 0px 50px 20px 0px rgba(0, 0, 0, 0),
      0px 28px 17px 0px rgba(0, 0, 0, 0.02), 0px 12px 12px 0px rgba(0, 0, 0, 0.03),
      0px 3px 7px 0px rgba(0, 0, 0, 0.03);
    @media (max-width: 768px) {
      background-color: var(--stile-green-2) !important;
      box-shadow: unset !important;
    }
  }

  .robotbee,
  .stars,
  .underline {
    display: inline-block;
    position: relative;
    :before {
      bottom: 5px;
      content: '';
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      background-position: top left;
      background-size: cover;
    }
  }
  .robotbee:before {
    left: -70px;
    top: -190px;
    width: 100px;
    height: 140.667px;
    z-index: 1;
    background-image: url('${robotbee}');
  }
  .stars:before {
    left: -25px;
    top: -90px;
    width: 38px;
    height: 50px;
    z-index: -1;
    background-image: url('${stars}');
  }
  .underline:before {
    left: -6px;
    bottom: -3px;
    width: 164.246px;
    height: 23.263px;
    z-index: -1;
    background-image: url('${underline}');
  }

  @media (max-width: 768px) {
    .stars:before,
    .robotbee:before {
      display: none;
    }
    .underline:before {
      background-image: url('${underlineMobile}');
      width: 114.972px;
      height: 16.284px;
    }
  }
`;

const TitleBackground = styled.div`
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: -75%;
    left: 50%;
    transform: translateX(-50%);
    width: 150%;
    max-width: 2800px;
    height: 120%;
    border-radius: 30%;
    background-color: var(--stile-green-2);
    background-image: url(${titleBackground});
    background-size: fill;
    background-position: center bottom;
    background-repeat: no-repeat;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
    z-index: -1;
    @media (max-width: 768px) {
      height: 95%;
      top: -55%;
      border-radius: 30%;
      background-image: url(${titleBackgroundMobile});
    }
  }
`;

const FooterBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  position: relative;
  overflow: hidden;
  height: 100%;
  margin-top: 100px;
  padding: 250px 0 80px;
  &::before {
    content: '';
    position: absolute;
    bottom: -131%;
    left: 50%;
    transform: translateX(-50%);
    width: 144%;
    height: 230%;
    border-radius: 50%;
    background-color: var(--stile-green-2);
    background-image: url(${footerBackground});
    background-size: fit;
    background-position: right top;
    background-repeat: repeat;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    z-index: -1;
    max-width: 2650px;
    min-width: 2116px;
  }
  .footer_container {
    max-width: 1160px;
    height: 80%;
    h1,
    h4,
    h6 {
      text-align: center;
      color: var(--white);
      margin: 80px 0;
    }
    @media (max-width: 1160px) {
      margin: 0 60px;
    }
    @media (max-width: 768px) {
      margin: 0;
    }
  }
  .top-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    h4 {
      max-width: 860px;
      margin: 0px;
    }
  }

  h6 {
    font-weight: 400;
    .bold {
      font-weight: 500;
      margin: 0 !important;
    }
  }
  @media (max-width: 768px) {
    .--huge {
      margin: 40px 0 0 !important;
      font-weight: 500;
      font-size: 50px;
      line-height: 50px;
    }
    h4 {
      margin: 0 !important;
    }
    h6 {
      margin: 24px 0 !important;
    }
    padding: 110px 25px 80px;
    &::before {
      margin-top: 60px;
      height: 150%;
      min-width: 1100px;
      bottom: -50%;
      background-position: center top;
      background-size: fit;

      background-image: url(${footerBackgroundMobile});
    }
  }
`;

const TitleMenuContainer = styled.div`
  .--huge {
    font-size: 105px !important;
    line-height: 100px !important;
    @media (max-width: 768px) {
      font-weight: 500;
      font-size: 50px !important;
      line-height: 50px !important;
    }
  }
  display: flex !important;
  flex-direction: column !important;
  padding-bottom: 80px !important;
  h1,
  p {
    color: var(--white);
  }
  p {
    max-width: 663px;
    font-size: 21px;
    font-weight: 400;
    line-height: 26px;
    @media (max-width: 768px) {
      font-size: 19px;
      line-height: 24px;
    }
  }
  .content {
    padding-top: 40px !important;
  }
  .video {
    overflow: visible !important;
  }
  video {
    border-radius: 32px;
    box-shadow: 0px 43px 26px 0px rgba(0, 0, 0, 0.04), 0px 19px 19px 0px rgba(0, 0, 0, 0.06),
      0px 5px 11px 0px rgba(0, 0, 0, 0.07);
    border: 1px solid var(--stile-grey-3);
    border: 2px solid #07fa66;

    margin: 78px auto 50px;
    @media (max-width: 768px) {
      border: 0.586px solid #07fa66;
      box-shadow: 0px 12.603px 7.621px 0px rgba(0, 0, 0, 0.04),
        0px 5.569px 5.569px 0px rgba(0, 0, 0, 0.06), 0px 1.466px 3.224px 0px rgba(0, 0, 0, 0.07);
      border-radius: 16px;
      margin: 23px auto 50px;
    }
  }
  @media (max-width: 768px) {
    padding: 0 25px 40px !important;
    margin: 0 !important;
    > div > div {
      padding-top: calc(32px - 15px) !important;
    }
    video {
      margin-top: 40px;
    }
  }
`;
const ReviewsComponent = styled.div`
  background-color: var(--stile-green-3);
  background-image: url(${testimonialBackground});
  background-size: fill;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    background-image: url(${testimonialBackgroundMobile});
  }
  .splide {
    &:nth-child(2) {
      .splide__track {
        padding-bottom: 72px;
        @media (max-width: 768px) {
          padding-bottom: 40px;
        }
      }
    }
  }
  .hearts {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    h4 {
      color: white;
      margin: 60px 20px 40px;
    }
    .quote {
      font-size: 14px !important;
      font-weight: 600 !important;
      line-height: 21px !important;

      @media (max-width: 768px) {
        line-height: 18px !important;
      }
    }
    .reviewer {
      font-weight: 400 !important;
    }
  }
`;
const SideBySideSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 150px;
  padding: 0 140px 50px;
  .video {
    min-width: 267px;
    object-fit: cover;
    aspect-ratio: 1;
    box-shadow: 0px 41.958px 25.37px 0px rgba(0, 0, 0, 0.04),
      0px 18.54px 18.54px 0px rgba(0, 0, 0, 0.06), 0px 4.879px 10.733px 0px rgba(0, 0, 0, 0.07);
  }
  p {
    font-size: 21px;
    font-weight: 400;
    line-height: 26px;
    @media (max-width: 768px) {
      font-size: 19px;
      line-height: 24px;
    }
  }
  @media (max-width: 768px) {
    padding: 60px 25px 60px;
    gap: 60px;
  }
`;
const StyledImageWrapper = styled.div`
  display: flex;
  min-width: 267px;
  justify-content: center;
  .greyBorder {
    border-color: var(--stile-grey-1);
    border-style: solid;
    border-width: 1px;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
  }

  .gatsby-image-wrapper,
  .overlay {
    border-radius: 32px;
  }
  .cover,
  .withShadow,
  .greyBorder,
  .video,
  .overlay {
    object-fit: cover;
    aspect-ratio: 1;
    box-shadow: 0px 41.958px 25.37px 0px rgba(0, 0, 0, 0.04),
      0px 18.54px 18.54px 0px rgba(0, 0, 0, 0.06), 0px 4.879px 10.733px 0px rgba(0, 0, 0, 0.07);
  }
  @media (max-width: 768px) {
    .gatsby-image-wrapper,
    .overlay {
      border-radius: 20px !important;
    }
    .cover {
      box-shadow: none;
    }
  }
`;

const ExamplesSection = styled<typeof EvidenceSection>(EvidenceSection)`
  padding-bottom: 76px;
  .content {
    padding-top: 150px !important;
    @media (max-width: 768px) {
      padding-top: 60px !important;
      padding-bottom: 20px !important;
    }
  }
  .--jumbo {
  margin: 0;}
  h2 {
    grid-column: 1 / span 12 !important;
  }
  .title {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    h1 {
    width: 70%;
    }
    a {
      max-height: 42px;
    }
  }
  @media (max-width: 768px) {
    padding-bottom: 0px;
    .title {
      h1 {
        width: 100% ;
      }
    }
    .mobile-button{
        display: flex;
        justify-content: center;
        grid-column: 1 / span 12 !important;
    }
  }
  .examples {
    grid-column: 1 / span 12;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    padding: 20px 0 32px 0;
    gap: 12px 13px;
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 32px 12px;
    }

  .exampleCard {
    padding: 15px;
    gap: 15px;
    display: flex;
    max-width: 280px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
    border: 1px solid var(--stile-grey-3);
    h6 {
      margin: 0;
    }
    .gatsby-image-wrapper {
      max-width: 100px;
      aspect-ratio: 1;
    }
    @media (max-width: 768px) {
      align-self: stretch;
      flex-direction: row;
      min-width: 100%;
      .gatsby-image-wrapper {
          min-width: 80px;
      }
    }
  }
`;

const FoundersNote = styled<typeof Section>(Section)`
  padding-bottom: 150px;
  .founders-note {
    grid-column: 1 / span 5;
    max-width: 440px;
    aspect-ratio: 1;
  }
  .founders-image {
    grid-column: 7 / span 6;
    border-radius: 37.624px;
    aspect-ratio: 566/700;
  }
  @media (max-width: 768px) {
    padding-bottom: 60px;
    .content {
      padding-top: 0px !important;
    }
    .founders-note {
      grid-column: 1 / span 12;
      grid-row: 3;
      max-width: none;
    }
    .gatsby-image-wrapper {
      grid-column: 1 / span 12;
    }
  }
`;

const CurriculumSection = styled.div`
  &:last-child {
    margin-bottom: 100px;
    @media (max-width: 768px) {
      margin-bottom: 0;
    }
  }
  display: flex;
  justify-content: center;
  position: relative;
  .container,
  .reverse-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    gap: 60px;
  }
  .left-img,
  .right {
    flex: 1;
    max-width: 566px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .left-img {
    margin-top: 35px;
  }
  .left-text {
    max-width: 498px;
  }
  .right-text {
    max-width: 468px;
  }
  .right-text,
  .left-text {
    flex: 1;
  }
  h1 {
    padding-bottom: 11px;
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      align-items: flex-start;
      gap: 18px;
    }
    .reverse-container {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
    .reverse-container {
      gap: 20px;
    }
    .left-img {
      margin-top: 12px;
    }
  }
`;

const TeamSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const TeamSectionContainer = styled.div`
  .content {
    padding-top: 70px !important;
    @media (max-width: 768px) {
      padding-top: 0px !important;
    }
  }
`;

const Technology = styled.div`
  padding-bottom: 150px;
  @media (max-width: 768px) {
    padding-bottom: 0px;
  }
`;

const VideoModal = styled.dialog`
  background-color: transparent;
  border: none;
  padding: 0;
  width: 90vw;
  height: 90vh;
  ::backdrop {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

const reviews = [
  {
    name: 'Elizabeth Neher',
    school: 'Lake Oswego',
    quote:
      'My favorite thing about Stile is that every feature in it is 100% customizable and editable by the teacher, so I can take the great platform that’s already there and make it work for my students.',
  },
  {
    name: 'Zach Adler',
    school: 'Briggs Middle School',
    quote:
      'What I like about Stile is that it isn’t students just staring at a screen, it is driving a conversation... This is by far the most excited I have been about curriculum.',
  },
  {
    name: 'Tavin Hamm',
    school: 'Armand Larive Middle School',
    quote:
      "I think that out of all the curricula I've used, this one is very tech-savvy, and I believe students will be more engaged.",
  },
  {
    name: 'McKenzie Perry',
    school: 'Siuslaw Middle School',
    quote:
      "I don't have to make it engaging because it’s already engaging. It's just so editable, but it doesn't have to be. It's the perfect happy medium.",
  },
  {
    name: 'Lex McQuillan',
    school: 'Cheldelin Middle school',
    quote: 'Stile has the best customer service I’ve ever had in any part of my life.',
  },
  {
    name: 'Jill Fischer',
    school: 'Peak Charter Academy',
    quote: 'I wish I had this when I was a first year teacher.',
  },
  {
    name: 'Delfino Osorio Garcia',
    school: 'Hermiston School District',
    quote:
      'I think the biggest complement that I saw in Stile was how easy it was to customize our lessons and make them specific to our region. The kids will be like, was this written for us?',
  },
  {
    name: 'Chelsea Landry',
    school: 'North Marion Middle School',
    quote:
      "The combination of the quality of the curriculum and the support that you get from the team is unlike any other curriculum that I've worked with.",
  },
  {
    name: 'Bettina Gregg',
    school: 'German International School',
    quote: "It engages kids in a way that a normal book wouldn't, or that I can't on my own.",
  },
  {
    name: 'Brittany Barker',
    school: 'North Carolina Middle School',
    quote: 'I got the highest scores in the district! Thank you Stile!!',
  },
];
export function Head(props: HeadProps) {
  return (
    <SEO
      title="The world-class science curriculum for Oregon"
      description="Stile is a leading digital learning platform offering science teaching resources to enhance students’ and teachers’ classroom experiences. Learn more here!"
      slug={props.location.pathname}
    />
  );
}

function HomePage(props: PageProps) {
  const [isMobile, setIsMobile] = React.useState(false);
  const videoModalRef = React.useRef<HTMLDialogElement>(null);
  const videoPlayerRef = React.useRef<any>(null);

  React.useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  React.useEffect(() => {
    if (videoModalRef.current) {
      videoModalRef.current.addEventListener('close', () => {
        videoPlayerRef.current?.getInternalPlayer()?.pauseVideo();
      });
      videoModalRef.current.addEventListener('click', (e) => {
        if (e.target === videoModalRef.current) {
          videoModalRef.current?.close();
        }
      });
    }
  }, []);

  // Handler to open the modal and play the video
  const handleVideoModalOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    videoModalRef.current?.showModal();
    videoPlayerRef.current?.getInternalPlayer()?.playVideo();
  };

  const images = [
    podcast,
    sean,
    stickyNotes,
    execs,
    tShirt,
    sandpit,
    workbooks,
    kat,
    tim,
    mel,
    jacky,
    alex,
    filming,
    cham,
    school,
    mark,
  ];
  const leftImages = images.slice(0, 8);
  const rightImages = images.slice(8, 16);
  return (
    <HomePageLayout {...props}>
      <TitleBackground>
        <TitleMenuContainer className="content">
          <TitleMenu>
            <Top
              headingStyle="huge"
              title="The science curriculum that teachers love"
              description="Stile is an innovative second-generation NGSS curriculum for middle school. Bring the latest real-world science to your classrooms and give teachers the agency to flourish."
            />
            <VideoPlayer url={homepage} />
            <Button
              className="iconButton"
              onClick={handleVideoModalOpen}
              label={
                <>
                  <Icon src={iconPlay} iconSize="20px" style={{ marginRight: 6 }} />
                  <span>Hear from Stile teachers and students</span>
                </>
              }
            />
          </TitleMenu>
          <Section
            backgroundColor="--white"
            headingContent={
              <span>
                A Stile classroom hums with inspired energy - hands up, minds racing, questions
                flying.
                <br />
                <br />
                Stile was built to help teachers feel powerful, prepared and unstoppable.
                <br />
                <br />
                It’s for schools that are...
                <br />
                <br />
                <StaticImage
                  className="greyBorder"
                  src="../../../stile-shared/assets/images/homepage/serious_about_science.svg"
                  alt="Serious about Science"
                  style={{ maxWidth: '387px' }}
                />
                {/* <Hearts /> */}
              </span>
            }
          />
        </TitleMenuContainer>
      </TitleBackground>
      <ReviewsComponent>
        <div className="hearts" style={{ position: 'relative' }}>
          <h4> Hear from our teachers</h4>
        </div>
        <ReviewsSlider reviews={reviews} header="Hear from our teachers" />
      </ReviewsComponent>
      <ExamplesSection
        backgroundColor="--white"
        headingContent={
          <div className="title">
            <h1 className="--jumbo">A complete, coherent science curriculum</h1>
            {!isMobile && (
              <Button
                style="secondary"
                newTab={false}
                href="/what-is-stile/whats-included/"
                label={<span>See what’s included</span>}
              />
            )}
          </div>
        }
        textColor="--stile-grey-8"
      >
        <div className="examples">
          <div className="exampleCard">
            <StaticImage
              src="../../../stile-shared/assets/images/homepage/instructional_material.png"
              alt="Instructional materials"
            />
            <div>
              <h6>Instructional materials</h6>
              <span>Beautiful, interactive science lessons</span>
            </div>
          </div>
          <div className="exampleCard">
            <StaticImage
              src="../../../stile-shared/assets/images/homepage/lab_kits.png"
              alt="Lab kits"
            />
            <div>
              <h6>Lab kits</h6>
              <span>Kits for vibrant hands-on classrooms</span>
            </div>
          </div>
          <div className="exampleCard">
            <StaticImage
              src="../../../stile-shared/assets/images/homepage/revision_workbooks.png"
              alt="Student workbooks"
            />
            <div>
              <h6>Student workbooks</h6>
              <span>Develop notetaking skills and consolidate learning</span>
            </div>
          </div>
          <div className="exampleCard">
            <StaticImage
              src="../../../stile-shared/assets/images/homepage/planning_guides.png"
              alt="Planning guides"
            />
            <div>
              <h6>Planning guides</h6>
              <span>Help teachers prepare for lessons</span>
            </div>
          </div>
          <div className="exampleCard">
            <StaticImage
              src="../../../stile-shared/assets/images/homepage/assessments.png"
              alt="Planning guides"
            />
            <div>
              <h6>Assessments</h6>
              <span>A complete system of diagnostic, formative and summative assessment</span>
            </div>
          </div>
          <div className="exampleCard">
            <StaticImage
              src="../../../stile-shared/assets/images/homepage/professional_learning.png"
              alt="Professional learning"
            />
            <div>
              <h6>Professional learning</h6>
              <span>Practical evidence-based professional learning that delivers real results</span>
            </div>
          </div>
          <div className="exampleCard">
            <StaticImage
              src="../../../stile-shared/assets/images/homepage/powerful_platform.png"
              alt="Powerful platform"
            />
            <div>
              <h6>Powerful platform</h6>
              <span>Made for dynamic classroom teaching.</span>
            </div>
          </div>
          <div className="exampleCard">
            <StaticImage
              src="../../../stile-shared/assets/images/homepage/responsive_support.png"
              alt="Responsive support"
            />
            <div>
              <h6>Responsive support</h6>
              <span>Our teachers and engineers are always at the ready.</span>
            </div>
          </div>
        </div>
        {isMobile && (
          <div className="mobile-button">
            <Button
              style="secondary"
              newTab={false}
              href="/what-is-stile/whats-included/"
              label={<span>See what’s included</span>}
            />
          </div>
        )}
      </ExamplesSection>
      <SideBySideSection>
        <CurriculumSection>
          <div className="reverse-container">
            <div className="left-text">
              <h1 className="--jumbo">
                From artificial <span className="robotbee" /> intelligence to robot bees
              </h1>
              <p>
                Students expect the science they learn at school to be modern and relevant to their
                lives. Stile’s units are built around phenomena students <em>actually</em> want to
                learn about, and students do activities that use science and engineering to solve
                global issues and advance society.
              </p>
            </div>
            <div className="right">
              <VideoPlayer url={lessons} loop={false} />
            </div>
          </div>
        </CurriculumSection>
        <CurriculumSection>
          <div className="container">
            <div className="left-img">
              <VideoPlayer url={curriculum} loop={false} />
            </div>
            <div className="right-text">
              <h1 className="--jumbo">
                It’s <span className="underline">your</span> curriculum
              </h1>
              <p>
                Every district operates in a unique context, with teachers that have developed
                resources that resonate with the students in the area.
                <br />
                <br /> Stile’s curriculum specialists work with your teachers to tailor our
                curriculum to you. We help you incorporate local phenomena, bring in your own
                resources, and map the scope and sequence to your calendar. <br /> <br /> The
                result? Your very own high quality customized science curriculum.
              </p>
            </div>
          </div>
        </CurriculumSection>
        <CurriculumSection>
          <div className="reverse-container">
            <div className="left-text">
              <h1 className="--jumbo">
                <span className="stars" />
                Magically updated for you, weekly
              </h1>
              <p>
                Science is moving fast. Datasets from 2012 may as well be prehistoric for today’s
                students. Our in-house team has their finger on the pulse, updating datasets and
                science news stories weekly, keeping them fresh for students and educators alike.
              </p>
            </div>
            <div className="right">
              <VideoPlayer url={updatedData} loop={false} />
            </div>
          </div>
        </CurriculumSection>
        <CurriculumSection>
          <div className="container">
            <div className="left-img">
              <VideoPlayer url={supportTeachers} loop={false} />
            </div>
            <div className="right-text">
              <h1 className="--jumbo">Support new teachers, empower veterans</h1>
              <p>
                Stile provides the support that graduate teachers need, and the flexibility
                experienced teachers deserve.
                <br />
                <br /> For a curriculum implementation to be successful it simply must cater to the
                entire teaching spectrum. That’s why we provide lesson plans, teaching tips, model
                answers, demo videos and more for newer teachers, while also providing experienced
                teachers with the tools to tweak and customize in line with their expertise and
                flair. Teacher agency is essential for student engagement.
              </p>
            </div>
          </div>
        </CurriculumSection>
      </SideBySideSection>
      <FoundersNote
        headingContent={<h1 className="--jumbo">From Stile’s founders</h1>}
        backgroundColor="--stile-grey-1"
        textColor="--stile-grey-8"
      >
        <div className="founders-note">
          <p>
            Young people deserve to graduate from school scientifically literate and ready to tackle
            tomorrow’s problems. Knowledge is power, and the ability to sift fact from fiction, make
            sense of data, and think critically is a life skill that’s more important than ever.
            With STEM careers among the fastest-growing worldwide, students need a strong foundation
            in science and critical thinking—right now.
            <br />
            <br />
            That’s where our brilliant teachers come in. Teachers are the key to shaping the next
            generation of problem-solvers, and we’re here to support them. We’re serious about
            providing the tools they need to excel in the classroom, save time, and deliver
            genuinely engaging learning experiences for every student.
            <br />
            <br />
            Call us passionate, call us meticulous—but we’re sticklers for quality. A clunky,
            unintuitive user experience? Not on our watch. Students and teachers deserve better, and
            the research backs us up: high-quality instructional materials aren’t a
            ‘nice-to-have’—they’re proven to significantly improve student outcomes.
            <br />
            <br />
            That’s what’s driven us for over 10 years, and we’re not slowing down. As three nerds
            who grew up devouring all things science, we’re relentless in our mission to improve
            education for all.
            <br />
            <br />
            Our goal? Simple: deliver world-class science education to every student.
            <br />
            <br />
            <StaticImage
              src="../../../stile-shared/assets/images/homepage/signatures.png"
              alt="signatures"
            />
            <br />
            <strong>Danny, Alan & Byron</strong>
          </p>
        </div>
        <StaticImage
          className="founders-image"
          src="../../../stile-shared/assets/images/homepage/founders.png"
          alt="Danny, Alan & Byron"
        />
      </FoundersNote>
      <ScrollJackedComponent
        mainHeading="Vibrant, hands-on classrooms"
        subHeadings={['Robust labs', 'Engineering challenges', 'Discussions & debates']}
        paragraphs={[
          'Students learn to observe phenomena, collect and document data, and analyze results to draw conclusions.',
          'Students apply engineering principles to design, prototype, and test solutions for real-world challenges.',
          'Students participate in thoughtful discussions and debates, challenging and defending ideas with logic and evidence.',
        ]}
        buttonLabel="Learn about our Lab Kits"
        buttonHref="/what-is-stile/lab-kits/"
        images={[
          {
            src: labs,
            alt: 'Robust labs',
          },
          {
            src: engineering,
            alt: 'Engineering challenges',
          },
          {
            src: discussions,
            alt: 'Discussions & debates',
          },
        ]}
      />
      <Technology>
        <TechnologySection />
        <div className="content">
          <WITBFeatureSection>
            <ScrollIndicatorContainer>
              <WITBGridRow>
                <WITBGridItem
                  title="Speed grading with AI"
                  detail="Stile’s in-house prompting engine analyzes student responses and provides draft feedback for teachers to nudge students in the right direction."
                />
                <WITBGridItem
                  title="Edit. Literally. Anything."
                  detail="Rearrange, remove, or tweak any part of your lesson. "
                />
                <WITBGridItem
                  title="Customize lessons"
                  detail="No rigid scripts here. Add the videos, questions, and activities you love, knowing your students will too."
                />
              </WITBGridRow>
              <WITBGridRow>
                <WITBGridItem
                  title="Differentiate lessons"
                  detail="Group students by ability and give them lessons that not only inspire and delight but match their skill levels too."
                />
                <WITBGridItem
                  title="Stimulate discussion"
                  detail="Sometimes, the magic of technology can help even the quietest students find their voice and stimulate rich discussion."
                />
                <WITBGridItem
                  title="Give timely feedback"
                  detail="Feedback is one of the most powerful tools for boosting student outcomes. It’s especially impactful when timely."
                />
              </WITBGridRow>
              <WITBGridRow>
                <WITBGridItem
                  title="Control student screens"
                  detail="Get students back on track and focused on you with just one click, using the Teleport and Pause buttons."
                />
                <WITBGridItem
                  title="Review individual work"
                  detail="Use Stile’s Gradebook to dive deep into each student’s response to every question."
                />
                <WITBGridItem
                  title="Class-wide analytics"
                  detail="GUse Stile’s Gradebook to dive deep into each student’s response to every question."
                />
              </WITBGridRow>
            </ScrollIndicatorContainer>
          </WITBFeatureSection>
        </div>
      </Technology>
      <ScrollJackedComponent
        mainHeading="Our expert science teachers team up with yours"
        subHeadings={['Training', 'Workshops', 'Stile Academy']}
        paragraphs={[
          'Video-based modules to learn the basics of Stile at your own pace.',
          'Tailored to your priorities, we come to you and deliver dynamic, practical in-person workshops.',
          'Earn micro-certifications by completing self-paced professional learning, with optional one-on-one coaching from a leading teacher.',
        ]}
        buttonLabel="Read more about Professional Learning"
        buttonHref="/what-is-stile/professional-learning"
        images={[
          {
            src: training,
            alt: 'Robust labs',
          },
          {
            src: workshops,
            alt: 'Engineering challenges',
          },
          {
            src: academy,
            alt: 'Discussions & debates',
          },
        ]}
      />
      <TeamSectionContainer>
        <Section
          backgroundColor="--white"
          headingContent={
            <h1 className="--jumbo">
              Designed by
              <br />
              teachers,
              <br />
              scientists,
              <br />
              artists, <br />
              and engineers,
              <br />
              supported by a <br />
              cast of experts.
            </h1>
          }
        ></Section>
        {isMobile ? (
          <>
            <ImageCarousel images={leftImages} />
            <Section
              headingContent={
                <>
                  <h2>For every lesson we spend</h2>
                  <h1 className="--jumbo" style={{ color: 'var(--stile-green-3)' }}>
                    50 hours
                  </h1>
                  <h2>finding modern phenomena,</h2>
                  <h2>scaffolding coherent lesson sequences,</h2>
                  <h2>crafting challenging questions,</h2>
                  <h2>creating custom simulations,</h2>
                  <h2>writing teacher supports,</h2>
                  <h2>and testing in classrooms.</h2>
                  <Button
                    label="Our guarantees & principles"
                    style="secondary"
                    newTab={false}
                    href="/who-we-are/our-principles/"
                  />
                </>
              }
            ></Section>
            <ImageCarousel images={rightImages} />
          </>
        ) : (
          <TeamSection>
            <ScrollingSection />
          </TeamSection>
        )}
      </TeamSectionContainer>
      <FooterBackground>
        <div className="footer_container">
          <div className="top-heading">
            <h4>Join a vibrant community of over 1,000 schools that are serious about science</h4>
          </div>
          <h1 className="--huge">“Stile helped me learn so much more.”</h1>
          <h6>
            <h6 className="bold">Savana</h6>
            Student, Whittington School
          </h6>
        </div>
      </FooterBackground>
      <VideoModal ref={videoModalRef}>
        <YouTubePlayer
          ref={videoPlayerRef}
          url="https://youtu.be/bBU6dKnL1oo"
          width="100%"
          height="100%"
          playing={false}
          controls={true}
          rel={0}
        />
      </VideoModal>
    </HomePageLayout>
  );
}

export default HomePage;
