import type { HeroBlockProps } from '../components/2020/blocks/HeroBlock';
import type { BaseSEOProps } from '../gatsby/BaseSEO';
import { getRegionFromLocale } from './getRegionFromLocale';
import { replacePrefix } from './replacePrefix';

type PageDataData = {
  seo: Pick<BaseSEOProps, 'title' | 'description' | 'uniqueToLocale' | 'opengraphImageSelection'>;
  hero: Required<Pick<HeroBlockProps, 'title' | 'text'>>;
  menu: { text: string; url: string };
};

export type PageData = Record<string, Record<string, PageDataData>>;

/**
 * Retrieve page data for a sectioned page (e.g. `what-is-stile` -> `stile-x`)
 */
export const getPageData = (pageData: PageData, locale: string) => (slug: string) => {
  // strip any additional path prefix from testing site branch etc
  if (process.env.GATSBY_TESTING_PREFIX) {
    slug = replacePrefix(slug, process.env.GATSBY_TESTING_PREFIX, '/');
  }

  // strip 'au' 'us' etc if passed
  if (locale) {
    slug = replacePrefix(slug, getRegionFromLocale(locale), '/');
  }

  // split and remove any leading or trailing slashes
  const splitPath = slug.split('/').filter(Boolean);

  // pageData only expects paths of depth = 2
  if (splitPath.length > 2) {
    console.warn(`Path is too long in getPageData for path: ${slug}`);
  }

  const [section, page = 'index'] = splitPath;
  return pageData?.[section]?.[page] ?? {};
};
