import React from 'react';

import { HeadProps, PageProps, graphql } from 'gatsby';
import styled from 'styled-components';

import SimulationDetailsComponent from 'stile-shared/src/components/2024/common/Simulations/SimulationDetailsComponent';
import { Simulation, simulationNameToSlug } from 'stile-shared/src/utils/simulations';

import { PageLayout } from 'templates/2024/PageLayout';
import { SEO } from 'templates/2024/SEO';

export function Head(
  props: HeadProps<object, { categoryName: string; categorySlug: string } & Simulation>
) {
  const { categoryName, categorySlug, ...simulation } = props.pageContext;
  if (!simulation || !simulation.name) return null;

  const plainDescription = simulation.shortDescriptionHtml?.replace(/<[^>]*>/g, '');

  return (
    <SEO
      slug={`/simulations/${categorySlug}/${simulationNameToSlug(simulation.name)}`}
      description={plainDescription}
      title={simulation.name}
      opengraphTitle={simulation.name}
      opengraphDescription={plainDescription}
    />
  );
}

const SimulationDetailPageLayout = styled(PageLayout)`
  .main-content {
    background-color: rgba(255, 255, 255, 0) !important;
    margin: 0;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }

  .content,
  .section {
    padding-top: 0 !important;
  }
`;

type InstructionalPageProps = PageProps & {
  pageContext: { categoryName: string; categorySlug: string } & Simulation;
  data: Queries.allSimulationsUsQuery;
};

function InstructionalPage({ data, pageContext }: InstructionalPageProps) {
  // Destructure pageContext so that simulation contains all the simulation fields
  const { categoryName, categorySlug, ...simulation } = pageContext;

  return (
    <SimulationDetailPageLayout>
      {simulation && simulation.name && (
        <SimulationDetailsComponent
          simulation={simulation}
          categoryName={categoryName}
          categorySlug={categorySlug}
          allSimulations={data.allSimulationsUs.nodes[0].simulations as Simulation[]}
          region="us"
        />
      )}
    </SimulationDetailPageLayout>
  );
}

export const query = graphql`
  query allSimulationsUs {
    allSimulationsUs {
      nodes {
        simulations {
          name
          uri
          topicsHtml
          shortDescriptionHtml
          subCategory
          scienceDisciplines
          learningObjectivesHtml
          longDescriptionHtml
          instructionsHtml
          simulationType
          grade
          coverImageUrl
          shortLink
          outcomesByCurriculumCode {
            US_OR {
              learningOutcomes {
                learningOutcomeDescription
                learningOutcomeCode
              }
            }
            US_NC {
              learningOutcomes {
                learningOutcomeDescription
                learningOutcomeCode
              }
            }
            AU_V9 {
              learningOutcomes {
                learningOutcomeDescription
                learningOutcomeCode
              }
            }
            AU_NSW_2023 {
              learningOutcomes {
                learningOutcomeDescription
                learningOutcomeCode
              }
            }
            US_HS {
              learningOutcomes {
                learningOutcomeDescription
                learningOutcomeCode
              }
            }
            US {
              learningOutcomes {
                learningOutcomeDescription
                learningOutcomeCode
              }
            }
          }
        }
      }
    }
  }
`;

export default InstructionalPage;
