import styled from 'styled-components';

export const TopSection = styled.div`
  background-color: var(--stile-grey-1);
  @media (max-width: 768px) {
    background-color: transparent;
  }

  .content {
    display: flex;
    flex-direction: row;
    position: relative;
    padding-bottom: 50px; // different
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      padding-bottom: 30px;
      margin-top: 28px;
    }

    .--left {
      width: 45%;
      h1 {
        margin-top: 80px;
        margin-bottom: 60px;
      }

      @media (max-width: 768px) {
        width: 100%;
        h1 {
          margin-top: 0;
          margin-bottom: 25px;
        }
      }
    }

    .--right {
      width: 50%;
      .gatsby-image-wrapper {
        margin-top: 70px;
      }

      @media (max-width: 768px) {
        .gatsby-image-wrapper {
          margin-top: 30px;
          margin-bottom: 45px;
        }
        width: 100%;
      }
    }

    h6,
    ul {
      margin: 0;
      padding: 0;
      width: 75%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .underline {
      margin-top: 20px; // different
      margin-bottom: 10px; // different
    }

    ul {
      width: 90%;
      list-style: none;
      padding-left: 0;
      margin: 0;
      li {
        display: flex;
        align-items: start;
        margin-top: 20px;
        img {
          margin-right: 12px;
          margin-top: 2px;
        }
      }
    }
  }
`;
