import React from 'react';

import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

interface TagMenuProps {
  type: 'link' | 'filter';
  filterValue: string;
  filterDisplay: string;
  url?: string;
  onFilterSelect?: (filterValue: string) => void; // Optional callback for filter selection
  left?: boolean; // Align to left
  right?: boolean; // Align to right
}

// Styled components
const TagMenuWrapper = styled.div<{ left?: boolean; right?: boolean }>`
  display: flex;
  overflow-x: auto;
  padding: 0 25px;
  gap: 15px;
  width: auto;

  justify-content: center; /* Default alignment */

  ${(props) =>
    props.left &&
    css`
      justify-content: flex-start; /* Left alignment */
      padding: 0;
    `}

  ${(props) =>
    props.right &&
    css`
      justify-content: flex-end; /* Right alignment */
      padding: 0;
    `}

    @media (max-width: 768px) {
    padding: 0 4px;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
`;

const MenuItem = styled.div`
  padding: 0 16px;
  border-radius: 16px;
  border: 2px solid var(--stile-green-2);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 31px;
  flex-shrink: 0;
  transition: border-color 0.3s ease;

  :hover {
    border-color: var(--stile-green-1);
  }
`;

const TagLabel = styled.div`
  color: var(--stile-grey-7);
  font-size: 14px;
  font-family: Graphik, sans-serif;
  font-weight: 400;
  line-height: 21px;
  white-space: nowrap;
  transition: color 0.3s ease;

  :hover {
    color: var(--stile-grey-8);
    cursor: pointer;
  }
`;

// Component definition
export const TagMenu: React.FC<TagMenuProps> = ({
  type,
  filterValue,
  filterDisplay,
  url,
  onFilterSelect,
  left,
  right,
}) => {
  return (
    <TagMenuWrapper left={left} right={right}>
      <MenuContainer>
        {type === 'link' && url ? (
          // Render as a link if type is "link" and URL is provided
          <Link to={url} style={{ textDecoration: 'none' }}>
            <MenuItem>
              <TagLabel>{filterDisplay}</TagLabel>
            </MenuItem>
          </Link>
        ) : (
          // Render as a filter item if type is "filter"
          <MenuItem onClick={() => onFilterSelect && onFilterSelect(filterValue)}>
            <TagLabel>{filterDisplay}</TagLabel>
          </MenuItem>
        )}
      </MenuContainer>
    </TagMenuWrapper>
  );
};
