import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

// Icon components
import EmailIcon from '../../../../../assets/icons/icon_social_email';
import FacebookIcon from '../../../../../assets/icons/icon_social_facebook';
import PinterestIcon from '../../../../../assets/icons/icon_social_pinterest';
// Functional components
import { Button } from '../Button';
import Toast from '../Toast/Toast';

const IconWrapper = styled.div<{ scale: number }>`
  width: ${({ scale }) => 100 * scale}px;
  height: ${({ scale }) => 100 * scale}px;
  cursor: pointer;
  position: relative;

  svg {
    width: 100%;
    height: 100%;
    path {
      transition: fill 0.15s ease-in-out;
    }
    &:hover {
      transform: translateY(-3px);
    }
  }
`;
const SocialShareContainer = styled.div`
  position: absolute;
  background: white;
  z-index: 999999;
  display: flex;
  max-width: 779px;
  padding: 25px 50px 40px 50px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 20px;
  box-shadow: 0px 43px 26px 0px rgba(0, 0, 0, 0.04), 0px 19px 19px 0px rgba(0, 0, 0, 0.06),
    0px 5px 11px 0px rgba(0, 0, 0, 0.07);
  h6 {
    margin: 0;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 25px 25px 40px 25px;
  }
`;

const ShareButtonsRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
`;

const Url = styled.div`
  display: flex;
  padding: var(--space-150, 12px) var(--space-200, 16px);
  align-items: center;
  gap: var(--space-0, 0px);
  align-self: stretch;
  border-radius: var(--radius-sm, 4px);
  border: var(--stroke-Default, 1px) solid var(--Primary-Stile-Grey-3, #dad4cc);
  background: var(--Primary-White, #fff);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  flex-shrink: 1;

  .url {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    flex-shrink: 1;
    width: 100%;
  }
`;

const CopyLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: var(--space-100, 8px);
  flex: 1 0 0;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

interface SocialShareProps {
  scale?: number; // Scale factor for the icon size
  url: string;
}

const SocialShare: React.FC<SocialShareProps> = ({
  url,
  scale = 0.5, // Default scale is 1
}) => {
  const [toastVisible, setToastVisible] = useState(false);

  const handleShare = (platform: 'pinterest' | 'facebook' | 'email' | 'copy') => {
    if (platform === 'copy') {
      navigator.clipboard.writeText(url).then(() => {
        setToastVisible(true);
      });
    } else {
      const shareUrls = {
        pinterest: `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}`,
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
        email: 'mailto:?subject=Check out this link&body=' + url,
      };
      const shareUrl = shareUrls[platform];
      window.open(shareUrl, '_blank', 'noopener,noreferrer');
    }
  };

  const handleToastClose = () => {
    setToastVisible(false);
  };

  return (
    <>
      <SocialShareContainer>
        <h6 className="h8">Share</h6>
        <ShareButtonsRow>
          <IconWrapper scale={scale} onClick={() => handleShare('email')}>
            <EmailIcon />
          </IconWrapper>
          <IconWrapper scale={scale} onClick={() => handleShare('facebook')}>
            <FacebookIcon />
          </IconWrapper>
          <IconWrapper scale={scale} onClick={() => handleShare('pinterest')}>
            <PinterestIcon />
          </IconWrapper>
        </ShareButtonsRow>
        <CopyLinkContainer>
          <Url>
            <span className="url">{url}</span>
          </Url>
          <Button style="primary" onClick={() => handleShare('copy')} label="Copy"></Button>
        </CopyLinkContainer>
      </SocialShareContainer>
      {toastVisible && (
        <Toast message="Link copied to your clipboard!" onClose={handleToastClose} />
      )}
    </>
  );
};

export default SocialShare;
