// src/pages/simulations.tsx
import React from 'react';

import { PageProps, graphql } from 'gatsby';
import styled from 'styled-components';

import SimulationsIndexPage from 'stile-shared/src/components/2024/common/Simulations/SimulationsIndexPage';
import { Simulation } from 'stile-shared/src/utils/simulations';

import { PageLayout } from 'templates/2024/PageLayout';

export { Head } from 'templates/2024/Head';

const SimulationsPageLayout = styled(PageLayout)`
  .grid-layout {
    margin: 0 auto;
  }

  .main-content {
    margin: 0 auto;
    padding-bottom: 120px;

    @media (max-width: 768px) {
      margin: 0 auto;
      padding-bottom: calc(60px - 28px);
    }
  }
`;

type SimulationsIndexPagePageProps = PageProps & {
  data: Queries.allSimulationsUsQuery;
};

function SimulationsPage({ data, ...props }: SimulationsIndexPagePageProps) {
  return (
    <SimulationsPageLayout {...props}>
      <SimulationsIndexPage
        allSimulations={data?.allSimulationsUs.nodes[0].simulations as Simulation[]}
        region="us"
      />
    </SimulationsPageLayout>
  );
}

export const query = graphql`
  query allSimulationsUs {
    allSimulationsUs {
      nodes {
        simulations {
          name
          uri
          topicsHtml
          shortDescriptionHtml
          subCategory
          scienceDisciplines
          learningObjectivesHtml
          longDescriptionHtml
          instructionsHtml
          simulationType
          grade
          coverImageUrl
          shortLink
          outcomesByCurriculumCode {
            US_OR {
              learningOutcomes {
                learningOutcomeDescription
                learningOutcomeCode
              }
            }
            US_NC {
              learningOutcomes {
                learningOutcomeDescription
                learningOutcomeCode
              }
            }
            AU_V9 {
              learningOutcomes {
                learningOutcomeDescription
                learningOutcomeCode
              }
            }
            AU_NSW_2023 {
              learningOutcomes {
                learningOutcomeDescription
                learningOutcomeCode
              }
            }
            US_HS {
              learningOutcomes {
                learningOutcomeDescription
                learningOutcomeCode
              }
            }
            US {
              learningOutcomes {
                learningOutcomeDescription
                learningOutcomeCode
              }
            }
          }
        }
      }
    }
  }
`;

export default SimulationsPage;
