import React from 'react';

import styled from 'styled-components';

import { Simulation } from '../../../../../../utils/simulations';

import { Section } from 'stile-shared/src/components/2024/layout/Section';

import ausCurriculum from './media/australian-curriculum.svg';
import concepts from './media/concepts-icon.svg';
import learning from './media/learning-icon.svg';
import ngss from './media/ngss.png';

const Details = styled(Section)`
  grid-column: 1 / -1 !important;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 40px;

  & .content {
    padding-top: 0 !important;
  }
`;

const DetailsTitle = styled.h4`
  grid-column: 1 / -1;
  margin: 26px 0 40px;
  text-align: left;

  @media (max-width: 768px) {
    margin: 6px 0 28px;
  }
`;

const DetailsContainer = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px 20px;
  justify-items: start;
  align-items: start;
  margin-left: 0;
  margin-right: auto;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    grid-column: 1 / -1 !important;
    grid-template-columns: 1fr;
    gap: 28px;
    padding-bottom: 30px;
  }
`;

const DetailsItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    gap: 15px;
  }
`;

const DetailsHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const DetailsIcon = styled.img`
  width: 38px;
  height: 38px;
`;

const DetailsHeading = styled.h6`
  margin: 0;
`;

const DetailsContent = styled.div`
  ul {
    margin: 0 16px;
    padding: 0 16px;

    li {
      list-style-type: disc;
      color: var(--stile-grey-8);
      font-size: 16px;
      line-height: 24px;

      p {
        padding: 0;
        margin: 0;
      }
    }
  }

  p {
    padding: 0 !important;
    top: 0;
  }

  a {
    color: var(--stile-grey-8);
    text-decoration: underline;
  }

  a:hover {
    color: var(--stile-green-2);
    transition: 300ms;
  }
`;

interface Props {
  simulation: Simulation;
  region: 'au' | 'us'; // Determines curriculum type
}

const SimulationDetails: React.FC<Props> = ({ simulation, region }) => {
  const allOutcomes =
    region === 'au'
      ? [...(simulation.outcomesByCurriculumCode?.AU_V9?.learningOutcomes || [])]
      : [
          ...(simulation.outcomesByCurriculumCode?.US_HS?.learningOutcomes || []),
          ...(simulation.outcomesByCurriculumCode?.US?.learningOutcomes || []),
        ];

  const curriculumLabel =
    region === 'au' ? (
      <>
        Australian Curriculum<sup>*</sup>
      </>
    ) : (
      'NGSS'
    );

  return (
    <Details>
      <DetailsTitle>Details</DetailsTitle>

      <DetailsContainer>
        {allOutcomes && allOutcomes.length > 0 && (
          <DetailsItem>
            <DetailsHeader>
              <DetailsIcon src={region == 'au' ? ausCurriculum : ngss} alt="Curriculum Icon" />
              <DetailsHeading>{curriculumLabel}</DetailsHeading>
            </DetailsHeader>
            <DetailsContent>
              <ul>
                {allOutcomes.map((outcome) => (
                  <li key={outcome.learningOutcomeCode}>
                    <strong>{outcome.learningOutcomeCode}: </strong>
                    {outcome.learningOutcomeDescription}
                  </li>
                ))}
              </ul>
            </DetailsContent>
            {region === 'au' && (
              <p>
                <sup>*</sup>This simulation is also aligned to the Victorian, NSW and Western
                Australian curriculums.
              </p>
            )}
          </DetailsItem>
        )}
        {simulation.learningObjectivesHtml && (
          <DetailsItem>
            <DetailsHeader>
              <DetailsIcon src={learning} alt="Learning Objectives Icon" />
              <DetailsHeading>Learning Objectives</DetailsHeading>
            </DetailsHeader>
            <DetailsContent
              dangerouslySetInnerHTML={{ __html: simulation.learningObjectivesHtml }}
            />
          </DetailsItem>
        )}
        {simulation.topicsHtml && (
          <DetailsItem>
            <DetailsHeader>
              <DetailsIcon src={concepts} alt="Conepts Icon" />
              <DetailsHeading>Concepts</DetailsHeading>
            </DetailsHeader>
            <DetailsContent dangerouslySetInnerHTML={{ __html: simulation.topicsHtml }} />
          </DetailsItem>
        )}
      </DetailsContainer>
    </Details>
  );
};

export default SimulationDetails;
