import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import highlight1 from 'stile-shared/static/highlight-1.svg';
import underline15 from 'stile-shared/static/underline-15.svg';
import underline16Mobile from 'stile-shared/static/underline-16-mobile.svg';
import underline16 from 'stile-shared/static/underline-16.svg';
import underline17 from 'stile-shared/static/underline-17.svg';
import underline18 from 'stile-shared/static/underline-18.svg';
import underline19Mobile from 'stile-shared/static/underline-19-mobile.svg';
import underline19 from 'stile-shared/static/underline-19.svg';
import underline20 from 'stile-shared/static/underline-20.svg';
import underline21Mobile from 'stile-shared/static/underline-21-mobile.svg';
import underline21 from 'stile-shared/static/underline-21.svg';
import underline22 from 'stile-shared/static/underline-22.svg';
import styled from 'styled-components';

import iconPage from 'stile-shared/assets/icons/icon_page.svg';

import { Button } from 'stile-shared/src/components/2024/common/Button';
import { ExploreCurriculum } from 'stile-shared/src/components/2024/common/ExploreCurriculum';
import Hearts from 'stile-shared/src/components/2024/common/Hearts';
import { Icon } from 'stile-shared/src/components/2024/common/Icon';
import ReferencesDropdown from 'stile-shared/src/components/2024/common/ReferencesDropdown';
import {
  EvidenceSection,
  StyledEvidenceCard,
} from 'stile-shared/src/components/2024/layout/CardSection';
import { Section } from 'stile-shared/src/components/2024/layout/Section';
import { TopSection } from 'stile-shared/src/components/2024/layout/TopSection';

import { PageLayout } from 'templates/2024/PageLayout';

export { Head } from 'templates/2024/Head';

const HQIMTopSection = styled<typeof TopSection>(TopSection)`
  h1 {
    grid-column: span 8 !important;
    margin-bottom: 36px !important;
  }
  ul {
    padding-left: 25px;
  }
  @media (max-width: 768px) {
  background-color: var(--stile-grey-1);
    h1 {
      grid-column: span 12 !important;;
    ul {
      padding-left: 25px;
    }
  }
`;

const HQIMLayout = styled<typeof PageLayout>(PageLayout)`
  .iconButton {
    padding-left: 16px !important;
  }
  .underline-15,
  .underline-18,
  .underline-19,
  .highlight-1 {
    display: inline-block;
    position: relative;
    z-index: 1;
    :before {
      bottom: 5px;
      content: '';
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      background-position: top left;
      background-size: cover;
      z-index: -1;
    }
  }

  .underline-16,
  .underline-17,
  .underline-20,
  .underline-21,
  .underline-22 {
    display: inline-block;
    position: relative;
    :after {
      bottom: 5px;
      content: '';
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      background-position: top left;
      background-size: cover;
    }
  }

  .highlight-1 {
    color: var(--white);
    :before {
      width: 180px;
      height: 85px;
      right: -10px;
      bottom: -2px;
      background-image: url('${highlight1}');
    }
  }
  .underline-15:before {
    width: 252px;
    height: 10px;
    right: -5px;
    bottom: 3px;
    background-image: url('${underline15}');
  }
  .underline-16:after {
    width: 105px;
    height: 10px;
    right: -5px;
    bottom: 3px;
    background-image: url('${underline16}');
  }
  .underline-17:after {
    width: 146px;
    height: 18px;
    bottom: -10px;
    background-image: url('${underline17}');
  }
  .underline-18:before {
    width: 191px;
    height: 13px;
    bottom: 0px;
    background-image: url('${underline18}');
  }
  .underline-19:before {
    width: 450px;
    height: 23px;
    left: 13px;
    bottom: -4px;
    background-image: url('${underline19}');
  }
  .underline-20 {
    :after {
      width: 99.07px;
      height: 20px;
      bottom: -5px;
      background-image: url('${underline20}');
    }
  }
  .underline-21 {
    :after {
      width: 366px;
      height: 28px;
      right: -2px;
      bottom: -3px;
      background-image: url('${underline21}');
    }
  }
  .textBlock {
    padding: 0 0 108px 0;
  }
  @media (max-width: 768px) {
    .underline-15:before {
      width: 160px;
      height: 6.25px;
      right: -5px;
      bottom: 0px;
    }
    .highlight-1:before {
      width: 111px;
      height: 48.75px;
      right: -3px;
      bottom: 2px;
    }
    .underline-16:after {
      width: 65.625px;
      height: 6.25px;
      right: -2px;
      bottom: 0px;
      background-image: url('${underline16Mobile}');
    }
    .underline-17:after {
      width: 91.25px;
      height: 11.25px;
      bottom: -7px;
    }
    .underline-18:before {
      width: 120px;
      height: 8.168px;
      bottom: -3px;
    }
    .underline-19:before {
      width: 117px;
      height: 10px;
      bottom: -2px;
      left: 8px;
      background-image: url('${underline19Mobile}');
    }
    .underline-20:after {
      width: 60.128px;
      height: 14.26px;
      right: 0px;
    }
    .underline-21:after {
      width: 221.643px;
      height: 8.006px;
      right: -2px;
      bottom: -3px;
      background-image: url('${underline21Mobile}');
    }
    .underline-22:after {
      width: 83px;
      height: 10px;
      bottom: -2px;
      right: -7px;
      background-image: url('${underline22}');
    }
    .header-wrapper {
      background-color: var(--stile-grey-1);
    }
    .textBlock {
      padding: 0 0 28px 0;

      .grid-layout {
        margin-top: 0 !important;
      }
    }
  }
  .hearts {
    display: inline-block;
    left: 40px;
    @media (max-width: 768px) {
      display: flex;
      justify-content: end;
      top: 30px;
      left: 0px;
    }
  }
`;

const VisualCard = styled.div`
  display: flex;
  max-width: 195px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-align: left;
  h3 {
    color: var(--stile-orange-2);
    margin: 0;
    text-align: center;
  }
  h4 {
    color: var(--stile-green-2);
    margin: 0;
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 156px;
    img {
      width: 106px;
    }
  }
`;

const Banner = styled<typeof Section>(Section)`
  @media (max-width: 768px) {
    .content {
      padding-top: 60px !important;
    }
  }
`;
const VisualSection = styled<typeof Section>(Section)`
  padding-bottom: 150px;
  .green {
    color: var(--stile-green-2);
    font-weight: 600;
  }
  .orange {
    color: var(--stile-orange-2);
    font-weight: 600;
  }
  .--right,
  .--green-right {
    margin-top: 88px;
    grid-column: 6 / span 7;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 40px;
    height: 650px;
  }
  .--left {
    grid-column: 1 / span 4;
    margin: 169.5px 0 37.5px;
    h4 {
      margin: 0 0 32px 0;
    }
  }
  @media (max-width: 768px) {
    padding-bottom: 60px;
    grid-template-columns: 1fr;
    .content {
      padding-top: 22px !important;
    }
    .--right,
    .--green-right {
      grid-column: 7 / span 6;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      height: auto;
      margin-top: 0;
      order: 1;
      gap: 30px;
    }
    .--left,
    .--right,
    .--green-right {
      grid-column: 1 / span 12;
      text-align: left;
      .small {
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .--green-right {
      grid-template-rows: repeat(2, 1fr);
      height: 441px;
      margin-bottom: 32px;
    }
    .--right {
      height: 729px;
      margin-bottom: 46px;
    }
    .--left {
      margin: 0 0 0 0;
      order: 2;
      h4 {
        margin-bottom: 12px;
      }
      p {
        margin-top: 12px;
        margin-bottom: 0;
      }
    }
  }
`;
const SecondVisualSection = styled<typeof VisualSection>(VisualSection)`
  .content {
    padding-top: 0px !important;
  }
  .grid-layout {
    margin-top: 0px !important;
  }
`;
const StyledBehindStileXCard = styled<typeof StyledEvidenceCard>(StyledEvidenceCard)`
   .--content {
    p {
      margin: 0;
    }
`;

const ScienceBehindStileXSection = styled<typeof EvidenceSection>(EvidenceSection)`
  .content {
    padding-top: 150px !important;
    @media (max-width: 768px) {
      padding-top: 60px !important;
      padding-bottom: 20px !important;
    }
  }

  .green-heading {
    color: var(--stile-green-3);
    margin: 80px 0 0 0;
    @media (max-width: 768px) {
        margin: 40px 0 0 0;
    }
  }
    h2 {
    @media (max-width: 768px) {
      text-align: center !important;
    }
  }
  .--subheader {
    margin-bottom: 80px;
    @media (max-width: 768px) {
      margin-bottom: 40px;
      text-align: center !important;
    }
  }
  .examples {
    grid-column: 1 / span 12;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    height: 536px;
    padding: 20px 0 0 0;
    gap: 80px 28px;
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 32px 12px;
      height: 712px;
    }

  .exampleCard {
    display: flex;
    max-width: 368px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    h6 {
      margin: 0;
    }
    @media (max-width: 768px) {
      width: 164px;
      .gatsby-image-wrapper {
          width: 100px;
      }
    }
  }
`;

const ExamplesSection = styled<typeof ScienceBehindStileXSection>(ScienceBehindStileXSection)`
  h2 {
    grid-column: 2 / span 10 !important;
  }
`;
const ReferencesSection = styled.div`
  grid-column: 1 / span 12;
`;

function TwentyTwentyFourHQIMPage(props: PageProps) {
  const exploreCards = [
    {
      title: 'Stile in the Classroom',
      description: 'Helping teachers implement evidence-based strategies.',
      url: '/what-is-stile/stile-classroom',
    },
    {
      title: 'Professional Learning',
      description:
        'Master Stile at your own pace with evidence-based pedagogies and personalised support.',
      url: '/what-is-stile/stile-pl/',
    },
    {
      title: "What's Included",
      description:
        'A complete, comprehensive curriculum—used, loved, and perfected by thousands of teachers.',
      url: '/what-is-stile/whats-included/',
    },
  ];
  const references = [
    'Australian Institute for Teaching and School Leadership. (2023). National Trends: Teacher Workforce. Retrieved June 17, 2024 from https://www.aitsl.edu.au/research/australian-teacher-workforce-data/publications-and-data-tools/national-trends-teacher-workforce#nav-pt7b-kf6',
    'TNTP, The Opportunity Myth (2018). Retrieved from https://tntp.org/wp-content/uploads/2023/02/TNTP_The-Opportunity-Myth_Web.pdf',
    'Agodini, R. et al. (2010). Achievement Effects of Four Early Elementary School Math Curricula: Findings for First and Second Graders. U.S. Department of Education.',
    'Boser, U., Chingos, M., & Straus, C. (2015). The Hidden Value of Curriculum Reform: Do States and Districts Receive the Most Bang for Their Curriculum Buck? Center for American Progress.',
    'Steiner, D., Magee, J., & Jensen, B. (2018). What we teach matters: How quality curriculum improves student outcomes. Johns Hopkins.',
    'Steiner, D., Magee, J., & Jensen, B. (2018). What we teach matters: How quality curriculum improves student outcomes. Johns Hopkins.',
  ];
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);
  return (
    <HQIMLayout {...props}>
      <HQIMTopSection
        headingContent="High quality instructional materials"
        subHeadingContent={
          <ul>
            <li>
              HQIM, or High Quality Instructional Materials, are resources meticulously crafted to
              meet curriculum standards.
            </li>
            <li>
              They save teachers hundreds of hours each year, giving them more time to nurture their
              students' needs.
            </li>
            <li>
              The results are clear: engaged learners, empowered teachers, and better outcomes for
              all.
            </li>
          </ul>
        }
        coverImage={
          <StaticImage
            src="../../../../stile-shared/assets/images/hqim/hqim-cover.png"
            alt="cover image"
            placeholder="none"
          />
        }
        underlineNumber={14}
      />

      <Banner
        className="textBlock"
        backgroundColor="--white"
        headingContent={
          <span>
            Workloads: <span className="underline-15">lightened</span>. <br />
            Student outcomes: boosted. <br />
            Lessons: loved by all.
            <div className="hearts" style={{ position: 'relative' }}>
              <Hearts />
            </div>
            {isMobile ? null : <br />}
            <br />
            That{`’`}s the power of <span className="highlight-1"> HQIM.</span>
            They don’t just align with curriculum standards—they{' '}
            <span className="underline-16">free</span> teachers to teach and students to{' '}
            <span className="underline-17">thrive.</span>
          </span>
        }
      ></Banner>
      <VisualSection
        backgroundColor="--stile-grey-8"
        headingColor="--white"
        textColor="--white"
        headingContent={
          <span>
            Currently, teacher workload is <span className="underline-21">unsustainable</span>
          </span>
        }
      >
        <div className="--left">
          <h4>
            Teacher by day,
            <br />
            lesson writer by night
          </h4>
          <p>
            High teacher workload is fuelling teacher turnover. The percentage of out-of-field
            teachers in STEM subjects is climbing, along with the proportion of teachers who are new
            to the profession.
            <br />
            <br />
            In addition to their responsibilities of classroom teaching, lesson preparation,
            feedback, assessment and much more, teachers frequently find themselves needing to write
            lesson materials in their own time, either from scratch or using online resources of
            varying quality.
            <br />
            <br />
            Teachers report spending an average of <span className="orange">
              7 hours a week
            </span>{' '}
            searching for instructional materials, and <span className="orange">5 hours </span>
            creating their own.<sup>1</sup>
          </p>
        </div>
        <div className="--right">
          <VisualCard>
            <StaticImage
              src="../../../../stile-shared/assets/images/hqim/141_percent.svg"
              alt="Review your school’s current approach"
            />
            <h3>141%</h3>
            <div className="--small">the percentage of contracted hours teachers are working</div>
          </VisualCard>
          <VisualCard>
            <StaticImage
              src="../../../../stile-shared/assets/images/hqim/44_percent.svg"
              alt="Review your school’s current approach"
            />
            <h3>44%</h3>
            <div className="--small">the percentage of teachers’ time spent on lesson planning</div>
          </VisualCard>
          <VisualCard>
            <StaticImage
              src="../../../../stile-shared/assets/images/hqim/41_percent.svg"
              alt="Review your school’s current approach"
            />
            <h3>41%</h3>
            <div className="--small">the percentage of out-of-field teachers in STEM subjects</div>
          </VisualCard>
          <VisualCard>
            <StaticImage
              src="../../../../stile-shared/assets/images/hqim/35_percent.svg"
              alt="Review your school’s current approach"
            />
            <h3>35%</h3>
            <div className="--small">
              the percentage of teachers who intend to leave the profession
            </div>
          </VisualCard>
          <VisualCard>
            <StaticImage
              src="../../../../stile-shared/assets/images/hqim/89_percent.svg"
              alt="Review your school’s current approach"
            />
            <h3>89%</h3>
            <div className="--small">
              the percentage of teachers who cite “workload and coping” as the main reason they plan
              to leave the profession
            </div>
          </VisualCard>
        </div>
      </VisualSection>
      <SecondVisualSection
        backgroundColor="--stile-grey-8"
        headingColor="--white"
        textColor="--white"
        headingContent={
          <span>
            HQIM save teachers <span className="underline-15">hundreds</span> of hours a year
          </span>
        }
      >
        <div className="--left">
          <h4>The result is improved student outcomes</h4>
          <p>
            Comprehensive quality-assured instructional materials offer an evidence-based starting
            point for teachers, saving hundreds of hours in the creation and curation of lesson
            plans, classroom activities and assessments. They provide the{' '}
            <span className="green">support graduate teachers need</span> and the flexibility
            experienced teachers deserve.
            <br />
            <br />
            That means teachers have more time to spend preparing to deliver lessons that are
            contextually relevant and <span className="green">responsive</span> to their students’
            needs and lived experiences.
          </p>
        </div>
        <div className="--green-right">
          <VisualCard>
            <StaticImage
              src="../../../../stile-shared/assets/images/hqim/6_hours.svg"
              alt="Review your school’s current approach"
            />
            <h4>6 hours</h4>
            <div className="--small">saved per teacher per week</div>
          </VisualCard>
          <VisualCard>
            <StaticImage
              src="../../../../stile-shared/assets/images/hqim/12_percent.svg"
              alt="Review your school’s current approach"
            />
            <h4>Up to 12%</h4>
            <div className="--small">
              difference in achievement levels <sup>2</sup>
            </div>
          </VisualCard>
          {isMobile ? null : <VisualCard />}
          <VisualCard>
            <StaticImage
              src="../../../../stile-shared/assets/images/hqim/7_months.svg"
              alt="Review your school’s current approach"
            />
            <h4>7 months</h4>
            <div className="--small">
              of achievement gap closed for underperforming students<sup>3</sup>
            </div>
          </VisualCard>
          <VisualCard>
            <StaticImage
              src="../../../../stile-shared/assets/images/hqim/40x_more.svg"
              alt="Review your school’s current approach"
            />
            <h4>40x</h4>
            <div className="--small">
              more cost effective than reduced class sizes<sup>4</sup>
            </div>
          </VisualCard>
        </div>
      </SecondVisualSection>
      <ScienceBehindStileXSection
        backgroundColor="--white"
        headingContent={
          <>
            Not all curriculum materials are created <span className="underline-18">equally</span>
          </>
        }
        textColor="--stile-grey-8"
      >
        <div className="--subheader">
          In a randomised control study from the US Department of Education, the provision of
          different curriculum materials alone resulted in a{' '}
          <strong>9–12 percentage point difference</strong> in student results.<sup>5</sup>
          <br /> <br />
          Australia is yet to create a centralized quality ratings body for curriculum materials
          used in our classrooms. That means that the careful attention of school leaders is
          required to ensure materials used in their classrooms meet the high-quality threshold.
          <h4 className="green-heading">How to select HQIM</h4>
        </div>

        <StyledBehindStileXCard
          title="Review your school’s current approach"
          copy={
            <div>
              <p>
                Consider the resources you currently purchase and whether they meet the high-quality
                threshold. Our helpful list (keep scrolling!) will support you in the review
                process.
              </p>
            </div>
          }
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/hqim/magnify.svg"
              alt="Review your school’s current approach"
            />
          }
        />
        <StyledBehindStileXCard
          title="Equip your teachers with HQIM"
          copy={
            <div>
              <p>
                When resource creation is no longer the teacher’s responsibility, they’re able to
                focus on customization, differentiation, providing feedback and building
                relationships. All of these things have a measurable impact on student outcomes. If
                your current resources don’t reach the bar, replace them with something that does.
              </p>
            </div>
          }
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/hqim/tech.svg"
              alt="Equip your teachers with HQIM"
            />
          }
        />
        <StyledBehindStileXCard
          title="Provide support for implementation"
          copy={
            <div>
              <p>
                School leadership has a significant role to play in the successful implementation of
                any initiative. Ensure that time and resources are allocated to supporting your
                staff to upskill in the effective use of the materials you purchase.
              </p>
            </div>
          }
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/hqim/support.svg"
              alt="Provide support for Implementation"
            />
          }
        />
      </ScienceBehindStileXSection>
      <Banner
        className="textBlock"
        backgroundColor="--stile-green-2"
        headingContent={
          <span>
            Over{' '}
            {isMobile ? (
              <>
                <span className="underline-22">50%</span> of the{' '}
                <span className="underline-19">benefits</span>
              </>
            ) : (
              <span className="underline-19">50% of the benefits</span>
            )}{' '}
            of switching to a comprehensive curriculum resource are{' '}
            <span className="underline-20">lost</span> if teachers don’t get proper training and
            support.
            <sup>6</sup>
          </span>
        }
      ></Banner>
      <ExamplesSection
        backgroundColor="--white"
        headingContent="So what does high quality look like?"
        textColor="--stile-grey-8"
      >
        <div className="--subheader">
          <Button
            className="iconButton"
            newTab={true}
            href="/resources/high-quality-instructional-materials/"
            label={
              <>
                <Icon src={iconPage} iconSize="20px" style={{ marginRight: 6 }} />
                <span>Download our HQIM checklist</span>
              </>
            }
          />
        </div>
        <div className="examples">
          <div className="exampleCard">
            <StaticImage
              src="../../../../stile-shared/assets/images/hqim/checklist.svg"
              alt="Review your school’s current approach"
            />
            <h6>Comprehensive, coherent and curriculum-aligned</h6>
          </div>
          <div className="exampleCard">
            <StaticImage
              src="../../../../stile-shared/assets/images/hqim/customisable.svg"
              alt="Review your school’s current approach"
            />
            <h6>Flexible and fully customisable by teachers</h6>
          </div>
          <div className="exampleCard">
            <StaticImage
              src="../../../../stile-shared/assets/images/hqim/real-world.svg"
              alt="Review your school’s current approach"
            />
            <h6>Up-to-date real-world, relevant contexts</h6>
          </div>
          <div className="exampleCard">
            <StaticImage
              src="../../../../stile-shared/assets/images/hqim/professional-learning.svg"
              alt="Review your school’s current approach"
            />
            <h6>Professional learning for effective implementation</h6>
          </div>
          <div className="exampleCard">
            <StaticImage
              src="../../../../stile-shared/assets/images/hqim/rocket.svg"
              alt="Review your school’s current approach"
            />
            <h6>Supports fast, efficient lesson preparation</h6>
          </div>
          <div className="exampleCard">
            <StaticImage
              src="../../../../stile-shared/assets/images/hqim/evidence-based.svg"
              alt="Review your school’s current approach"
            />
            <h6>Evidence-based pedagogies for better student outcomes </h6>
          </div>
        </div>
        <ReferencesSection>
          <ReferencesDropdown references={references} />
        </ReferencesSection>
      </ExamplesSection>
      <ExploreCurriculum
        title="Explore our core science curriculum"
        exploreCardsData={exploreCards}
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/responsive-support/book-with-grad-cap.svg"
            alt="A book with a graduation cap"
          />
        }
      />
    </HQIMLayout>
  );
}

export default TwentyTwentyFourHQIMPage;
