import React from 'react';

import styled from 'styled-components';

import { Link } from 'stile-shared/src/components/2024/common/Link';

// Styled components
const SimImage = styled.img.attrs({
  onError: (e: React.SyntheticEvent<HTMLImageElement>) => {
    (e.target as HTMLImageElement).src =
      'https://stileeducation.com/au/static/8e43f4232292ec71fa2fe7e771eec120/83395/in_class.webp';
  },
})`
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  border-radius: 1rem;
  border: 1px solid var(--stile-grey-2);
  box-shadow: 0px 5px 11px 0px #00000012;
  z-index: 20;
  object-fit: cover;
  opacity: 1;

  @media (max-width: 768px) {
    grid-column: span 8;
    border-radius: 0.92em;
    border-width: 0.92px;
    box-shadow: 0px 5px 11px 0px #00000012;
  }
`;

const SimGroup = styled(Link)`
  grid-column: span 4;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: inherit;
  gap: 3px;
  margin: 0 0 60px;

  &:hover ${SimImage} {
    box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.07), 0px 19px 19px 0px rgba(0, 0, 0, 0.06);
    border: 1px solid var(--stile-green-1);
  }

  @media (max-width: 768px) {
    grid-column: span 12;
    width: 100%;
    flex-shrink: 0;
    margin: 0 0 20px;
  }
`;

const SimName = styled.h6`
  margin: 12px 0 0 0;
`;

const Description = styled.p`
  color: black;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
`;

const SchoolSubject = styled.span`
  font-size: 14px;
  color: var(--stile-grey-6);
  padding: 0 0 12px;
`;

// Main component
interface SimMenuTileProps {
  imgSrc: string;
  title: string;
  description: string;
  category: string;
  url: string;
}

const SimMenuTile: React.FC<SimMenuTileProps> = ({ imgSrc, title, description, category, url }) => (
  <SimGroup url={url}>
    <SimImage src={imgSrc} alt={title} />
    <SimName>{title}</SimName>
    <Description>{description}</Description>
    <SchoolSubject>{category}</SchoolSubject>
  </SimGroup>
);

export default SimMenuTile;
