import React from 'react';

import styled from 'styled-components';

import { VideoPlayer } from 'stile-shared/src/components/2024/common/VideoPlayer';

const VideoGalleryContainer = styled.div`
  z-index: 99;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 15fr 13fr 29fr 13fr 15fr;
  grid-template-rows: auto auto;
  gap: 16px;
  padding: 0 0 90px;
  width: calc(100% * 1.225);
  max-width: 1440px;

  .video,
  .gif {
    object-fit: fill;
    aspect-ratio: 1;
    border-radius: 32px;
    box-shadow: 0px 80px 31px 0px rgba(0, 0, 0, 0.02), 0px 43px 26px 0px rgba(0, 0, 0, 0.04),
      0px 19px 19px 0px rgba(0, 0, 0, 0.06), 0px 5px 11px 0px rgba(0, 0, 0, 0.07);
  }

  @media (max-width: 768px) {
    width: calc(100% * 1.7);
    align-items: start;
    height: 100%;
    grid-template-columns: 115fr 55fr 29fr 196fr 29fr 55fr 115fr;
    grid-template-rows: 85fr 85fr 76fr 76fr 76fr 85fr 85fr;
    gap: 10px;
    padding: 80px 0 70px;
    .video,
    .gif {
      border-radius: 16px !important;
      box-shadow: 0px 80px 31px 0px rgba(0, 0, 0, 0.02), 0px 43px 26px 0px rgba(0, 0, 0, 0.04),
        0px 19px 19px 0px rgba(0, 0, 0, 0.06), 0px 5px 11px 0px rgba(0, 0, 0, 0.07);
    }
  }
`;
interface MediaItem {
  type: 'video' | 'gif';
  url: string;
}

interface VideoGalleryProps {
  items: MediaItem[];
}

export const VideoGallery: React.FC<VideoGalleryProps> = ({ items }) => {
  if (items.length !== 7) {
    throw new Error('The items array must contain exactly 7 elements.');
  }
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);
  if (isMobile) {
    return (
      <VideoGalleryContainer>
        {items[0] && (
          <div style={{ gridColumn: '2 / span 2', gridRow: '2' }}>
            {items[0].type === 'video' ? (
              <VideoPlayer url={items[0].url} />
            ) : (
              <div className="gif">
                <img src={items[0].url} alt="Media 1" />
              </div>
            )}
          </div>
        )}
        {items[1] && (
          <div style={{ gridColumn: '4', gridRow: '1 / span 2' }}>
            {items[1].type === 'video' ? (
              <VideoPlayer url={items[1].url} />
            ) : (
              <div className="gif">
                <img src={items[1].url} alt="Media 1" />
              </div>
            )}
          </div>
        )}
        {items[2] && (
          <div style={{ gridColumn: '1 / span 2', gridRow: '3 / span 2' }}>
            {items[2].type === 'video' ? (
              <VideoPlayer url={items[2].url} />
            ) : (
              <div className="gif">
                <img src={items[2].url} alt="Media 3" />
              </div>
            )}
          </div>
        )}
        {items[3] && (
          <div style={{ gridColumn: '3 / span 3 ', gridRow: '3 / span 3' }}>
            {items[3].type === 'video' ? (
              <VideoPlayer url={items[3].url} />
            ) : (
              <div className="gif">
                <img src={items[3].url} alt="Media 4" />
              </div>
            )}
          </div>
        )}
        {items[4] && (
          <div style={{ gridColumn: '6 / span 2', gridRow: '4 / span 2' }}>
            {items[4].type === 'video' ? (
              <VideoPlayer url={items[4].url} />
            ) : (
              <div className="gif">
                <img src={items[4].url} alt="Media 5" />
              </div>
            )}
          </div>
        )}
        {items[5] && (
          <div style={{ gridColumn: '4', gridRow: '6 / span 2' }}>
            {items[5].type === 'video' ? (
              <VideoPlayer url={items[5].url} />
            ) : (
              <div className="gif">
                <img src={items[5].url} alt="Media 6" />
              </div>
            )}
          </div>
        )}
        {items[6] && (
          <div style={{ gridColumn: '5 / span 2', gridRow: '6' }}>
            {items[6].type === 'video' ? (
              <VideoPlayer url={items[6].url} />
            ) : (
              <div className="gif">
                <img src={items[6].url} alt="Media 7" />
              </div>
            )}
          </div>
        )}
      </VideoGalleryContainer>
    );
  } else {
    return (
      <VideoGalleryContainer>
        {items[0] && (
          <div style={{ gridColumn: '1', gridRow: '1' }}>
            {items[0].type === 'video' ? (
              <VideoPlayer url={items[0].url} />
            ) : (
              <div className="gif">
                <img src={items[0].url} alt="Media 1" />
              </div>
            )}
          </div>
        )}
        {items[1] && items[2] && (
          <div
            style={{
              gridColumn: '2',
              gridRow: '1',
              gap: '16px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {items[1].type === 'video' ? (
              <VideoPlayer url={items[1].url} />
            ) : (
              <div className="gif">
                <img src={items[1].url} alt="Media 2" />
              </div>
            )}
            {items[2].type === 'video' ? (
              <VideoPlayer url={items[2].url} />
            ) : (
              <div className="gif">
                <img src={items[2].url} alt="Media 3" />
              </div>
            )}
          </div>
        )}
        {items[3] && (
          <div style={{ gridColumn: '3', gridRow: '1' }}>
            {/* Hero column: 6fr wide */}
            {items[3].type === 'video' ? (
              <VideoPlayer url={items[3].url} />
            ) : (
              <div className="gif">
                <img src={items[3].url} alt="Media 4" />
              </div>
            )}
          </div>
        )}
        {items[4] && items[5] && (
          <div
            style={{
              gridColumn: '4',
              gridRow: '1',
              gap: '16px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {items[4].type === 'video' ? (
              <VideoPlayer url={items[4].url} />
            ) : (
              <div className="gif">
                <img src={items[4].url} alt="Media 5" />
              </div>
            )}
            {items[5].type === 'video' ? (
              <VideoPlayer url={items[5].url} />
            ) : (
              <div className="gif">
                <img src={items[5].url} alt="Media 6" />
              </div>
            )}
          </div>
        )}
        {items[6] && (
          <div style={{ gridColumn: '5', gridRow: '1' }}>
            {items[6].type === 'video' ? (
              <VideoPlayer url={items[6].url} />
            ) : (
              <div className="gif">
                <img src={items[6].url} alt="Media 7" />
              </div>
            )}
          </div>
        )}
      </VideoGalleryContainer>
    );
  }
};

export default VideoGallery;
