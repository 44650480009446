import '@splidejs/react-splide/css';

import React from 'react';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import styled from 'styled-components';

interface ImageCarouselProps {
  images: string[];
}

const CarouselContainer = styled.div`
  width: 100%;
`;

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
  const splideOptions = {
    type: 'loop',
    perPage: 1,
    perMove: 1,
    rewind: false,
    pagination: false,
    gap: '12px',
    width: '100%',
    fixedWidth: '246px',
    fixedHeight: '289px',
    arrows: false,
    trimSpace: true,
    // Auto-scroll configuration:
    autoScroll: {
      pauseOnHover: false,
      pauseOnFocus: false,
      rewind: true,
      speed: 0.5,
    },
  };

  return (
    <CarouselContainer>
      <Splide options={splideOptions} extensions={{ AutoScroll }}>
        {images.map((imageSrc, index) => (
          <SplideSlide key={index} style={{ maxWidth: '246px', maxHeight: '289px' }}>
            <img
              src={imageSrc}
              alt={`Carousel ${index + 1}`}
              style={{
                width: 'auto',
                height: 'auto',
                display: 'block',
                maxWidth: '246px',
                maxHeight: '289px',
                borderRadius: '12px',
              }}
            />
          </SplideSlide>
        ))}
      </Splide>
    </CarouselContainer>
  );
};

export default ImageCarousel;
