import { ca } from 'date-fns/locale';

export type Grade =
  | 'PRE_SCHOOL'
  | 'PREP'
  | 'ONE'
  | 'TWO'
  | 'THREE'
  | 'FOUR'
  | 'FIVE'
  | 'SIX'
  | 'SEVEN'
  | 'EIGHT'
  | 'NINE'
  | 'TEN'
  | 'MS'
  | 'HS';

export const gradeOrder: Record<Grade, number> = {
  MS: 0,
  HS: 1,
  ONE: 2,
  TWO: 3,
  THREE: 4,
  FOUR: 5,
  FIVE: 6,
  SIX: 7,
  SEVEN: 8,
  EIGHT: 9,
  NINE: 10,
  TEN: 11,
  PRE_SCHOOL: 12,
  PREP: 13,
};

export const AU_GRADE_LABELS: Record<Grade, string> = {
  PRE_SCHOOL: 'Pre-School',
  PREP: 'Prep',
  ONE: 'Year 1',
  TWO: 'Year 2',
  THREE: 'Year 3',
  FOUR: 'Year 4',
  FIVE: 'Year 5',
  SIX: 'Year 6',
  SEVEN: 'Year 7',
  EIGHT: 'Year 8',
  NINE: 'Year 9',
  TEN: 'Year 10',
  MS: 'Middle School',
  HS: 'High School',
};
const CATEGORY_BLURBS: Record<Grade | ScienceDiscipline, string> = {
  PHYSICS:
    'Physics is the science of why and how—why things move, how energy transfers, and why toast always lands butter-side down. It’s more than formulas; it’s problem-solving at its best. With interactive simulations, students can experiment with forces, test Newton’s Laws, and explore space travel—all without the spacesuit.',
  BIOLOGY:
    'Biology is life—literally. It’s the story of everything that grows, breathes, and evolves, from tiny cells to entire ecosystems. Why do moths change colour? How do diseases spread? What happens if we lose our pollinators? With interactive tools that bring science to life, students can explore the living world in ways that are hands-on, relevant, and full of discovery.',
  CHEMISTRY:
    'Chemistry is where molecules mix, bonds form, and reactions (safely) explode. It’s the science behind everything—from how soap cleans to why bread rises. With Stile simulations, students can test reaction rates, build polymers, and explore atomic structures—no clean-up required.',
  EARTH_SCIENCE:
    'Earth Science is the ultimate big-picture subject—from the ground beneath us to the vastness of space. It’s about the forces shaping our world, humanity’s impact, and whether we could ever terraform Mars (spoiler: tricky). With interactive simulations, students can explore sustainability, energy, and planetary science in a hands-on, thought-provoking way.',
  MATH: 'Maths/Math isn’t just numbers—it’s patterns, problem-solving, and making sense of the world. Our interactive tools bring equations, probability, and data to life, helping students see the maths all around them. With probability wheels, real-world trends, and visual problem-solving, every lesson is practical, engaging, and (dare we say) enjoyable.',
  SIX: 'Sixth grade is all about uncovering the “how” and “why” behind the world around us. Why does friction slow things down? How do atoms form bonds? What’s really going on inside a single cell? With hands-on simulations, students don’t just learn science—they experience it.',
  PRE_SCHOOL: '',
  PREP: '',
  ONE: '',
  TWO: '',
  THREE: '',
  FOUR: '',
  FIVE: '',
  SEVEN:
    'Seventh grade is where science starts making connections. Newton’s Laws aren’t just theories—they’re in action every time you move. Chemical bonds aren’t just diagrams—they explain everything from rust to fireworks. With interactive tools, students explore science, not just study it.',
  EIGHT:
    'Eighth grade is the launchpad to high school science—bigger questions, deeper investigations, and more “aha” moments. How do ocean currents shape climate? What can your DNA actually tell you? With interactive simulations, abstract concepts come into focus and science gets real.',
  NINE: '',
  TEN: '',
  MS: 'Middle school science is all about big questions—and real experiments. Why do some chemicals explode while others don’t react? How does a tiny mutation create a new species? With interactive tools to model earthquakes, track evolution, and test forces in motion, science becomes an adventure, not just a set of facts.',
  HS: 'Science gets serious—but it doesn’t have to be dry. Interactive simulations bring big ideas to life, from reaction rates to space travel and genetics. Whether investigating climate change, modeling chemical bonds, or studying energy transformations, these lessons take students beyond the textbook and straight into discovery.',
};

export const US_GRADE_LABELS: Record<Grade, string> = {
  PRE_SCHOOL: 'Pre-School',
  PREP: 'Prep',
  ONE: '1st Grade',
  TWO: '2nd Grade',
  THREE: '3rd Grade',
  FOUR: '4th Grade',
  FIVE: '5th Grade',
  SIX: '6th Grade',
  SEVEN: '7th Grade',
  EIGHT: '8th Grade',
  NINE: '9th Grade',
  TEN: '10th Grade',
  MS: 'Middle School',
  HS: 'High School',
};

export type ScienceDiscipline = 'PHYSICS' | 'BIOLOGY' | 'CHEMISTRY' | 'EARTH_SCIENCE' | 'MATH';

export const SCIENCE_DISCIPLINE_LABELS: Record<ScienceDiscipline, string> = {
  PHYSICS: 'Physics',
  BIOLOGY: 'Biology',
  CHEMISTRY: 'Chemistry',
  EARTH_SCIENCE: 'Earth Science',
  MATH: 'Math',
};

export type SubCategory = 'ECOLOGY' | 'CELL_BIOLOGY' | 'NOT_SET';

export const SUB_CATEGORY_LABELS: Record<SubCategory, string> = {
  ECOLOGY: 'Ecology',
  CELL_BIOLOGY: 'Cell Biology',
  NOT_SET: '',
};
export type SimulationType = 'SIMULATION' | 'INTERACTIVE';

export const SimulationTypes: Record<SimulationType, string> = {
  SIMULATION: 'Simulation',
  INTERACTIVE: 'Interactive',
};

export const ShortLinks: Record<string, string> = {
  'https://stileapp.com/simulations/v2/cosmos/ecosystems/plastic-builder/index.html': 'atom1',
  'https://stileapp.com/simulations/v2/cosmos/elements/molecule-builder/index.html': 'atom2',
  'https://stileapp.com/simulations/v2/cosmos/evolution/moths/index.html': 'moths',
  'https://stileapp.com/simulations/v2/cosmos/skill-builders/wheel-decide/index.html': 'wheel',
  'https://stileapp.com/simulations/v2/cosmos/earth-systems/terraforming/index.html': 'terraform',
  'https://stileapp.com/simulations/v2/cosmos/resources/power-house/index.html': 'coal',
  'https://stileapp.com/simulations/v2/cosmos/save-the-bees/food-production/index.html': 'bees',
  'https://stileapp.com/simulations/v2/cosmos/ecosystems/the-plastic-you-use/index.html': 'plastic',
  'https://stileapp.com/simulations/v2/cosmos/newtons-laws/space-boy-open/index.html': 'spaceboy',
  'https://stileapp.com/simulations/v2/cosmos/resources/resources-room/index.html': 'room',
  'https://stileapp.com/simulations/v2/cosmos/universe/universe-zoom/index.html': 'universe1',
  'https://stileapp.com/simulations/v2/cosmos/cells/microscope-sim/index.html': 'microscope',
  'https://stileapp.com/simulations/v2/cosmos/ecosystems/ecosystem-part-1/index.html': 'marine1',
  'https://stileapp.com/simulations/v2/cosmos/ecosystems/ecosystem-part-2/index.html': 'marine2',
  'https://stileapp.com/simulations/v2/cosmos/immunity/measles/index.html': 'immunity',
  'https://stileapp.com/simulations/v2/cosmos/newtons-laws/space-crash/index.html': 'fma',
  'https://stileapp.com/simulations/v2/cosmos/rates-of-reaction/single-temp-none/index.html':
    'temp',
  'https://stileapp.com/simulations/v2/cosmos/reaction-types/reactions-area/index.html': 'react1',
  'https://stileapp.com/simulations/v2/cosmos/reaction-types/reactions-temperature/index.html':
    'react2',
  'https://stileapp.com/simulations/v2/cosmos/reactions/activation-energy-1/index.html': 'energy1',
};

export type Simulation = {
  name: string;
  uri: string;
  topicsHtml: string;
  shortDescriptionHtml: string;
  subCategory: SubCategory;
  scienceDisciplines: ScienceDiscipline[];
  learningObjectivesHtml: string;
  longDescriptionHtml: string;
  instructionsHtml: string;
  simulationType: string;
  keepLearningHtml: string;
  grade: Grade[] | null;
  coverImageUrl: string;
  shortLink: string | null;
  outcomesByCurriculumCode: {
    US_OR?: CurriculumOutcomes | null;
    US_NC?: CurriculumOutcomes | null;
    AU_V9?: CurriculumOutcomes | null;
    AU_NSW_2023?: CurriculumOutcomes | null;
    US_HS?: CurriculumOutcomes | null;
    US?: CurriculumOutcomes | null;
  };
};

export type CurriculumOutcomes = {
  learningOutcomes: LearningOutcome[];
};

export type LearningOutcome = {
  learningOutcomeDescription: string;
  learningOutcomeCode: string;
};

export type AllSimulationsUsData = {
  data: {
    allSimulationsUs: {
      nodes: {
        simulations: Simulation[];
      }[];
    };
  };
};

export type AllSimulationsAuData = {
  data: {
    allSimulationsAu: {
      nodes: {
        simulations: Simulation[];
      }[];
    };
  };
};

export function gradeToHumanReadable(grade: Grade | [] | null, region: 'au' | 'us') {
  if (grade && grade.length == 0) {
    return '';
  } else if (typeof grade === 'string') {
    if (region === 'au') {
      return AU_GRADE_LABELS[grade];
    } else {
      return US_GRADE_LABELS[grade];
    }
  }
  return '';
}

export function scienceDisciplineToHumanReadable(scienceDiscipline: ScienceDiscipline) {
  return SCIENCE_DISCIPLINE_LABELS[scienceDiscipline];
}

export function subCategoryToHumanReadable(subCategory: SubCategory) {
  return SUB_CATEGORY_LABELS[subCategory];
}

export function simulationNameToSlug(name: string) {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9\s]/g, '') // Remove all special characters except letters, numbers, and spaces
    .trim() // Trim any leading/trailing spaces
    .replace(/\s+/g, '-'); // Replace spaces with hyphens
}

export function simulationToSlug(
  name: string,
  scienceDisciplines: ScienceDiscipline[],
  grades: Grade[] | [] | null,
  region: 'au' | 'us'
) {
  const sluggedName = simulationNameToSlug(name);

  const disciplineSlugs = scienceDisciplines.map((discipline) => {
    return {
      categoryName: scienceDisciplineToHumanReadable(discipline),
      categorySlug: scienceDisciplineToSlug(discipline),
      slug: `${scienceDisciplineToSlug(discipline)}/${sluggedName}`,
    };
  });

  const gradeSlugs =
    grades && grades.length > 0
      ? grades.map((grade) => {
          return {
            categoryName: gradeToHumanReadable(grade, region),
            categorySlug: gradeToSlug(grade, region),
            slug: `${gradeToSlug(grade, region)}/${sluggedName}`,
          };
        })
      : [];

  return [...disciplineSlugs, ...gradeSlugs];
}

export function gradeToSlug(grade: Grade | [] | null, region: 'au' | 'us') {
  if (!grade) {
    return '';
  }
  const label = gradeToHumanReadable(grade, region);
  return label.toLowerCase().replace(/\s+/g, '-');
}

export function scienceDisciplineToSlug(scienceDiscipline: ScienceDiscipline) {
  const label = scienceDisciplineToHumanReadable(scienceDiscipline);
  return label.toLowerCase().replace(/\s+/g, '-');
}

export function subCategoryToSlug(subCategory: SubCategory) {
  const label = subCategoryToHumanReadable(subCategory);
  return label.toLowerCase().replace(/\s+/g, '-');
}

export function getCategoryBlurb(category: Grade | ScienceDiscipline) {
  return CATEGORY_BLURBS[category];
}

export function getShortlink(uri: string) {
  return uri in ShortLinks ? ShortLinks[uri] : null;
}

export function getSimulationType(simulationType: SimulationType) {
  return SimulationTypes[simulationType];
}
