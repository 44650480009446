import * as React from 'react';

import type { HeadProps, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { CtaBlock } from 'stile-shared/src/components/2020/blocks/CtaBlock';

import { PageLayout } from 'templates/2020/PageLayout';
import { SEO } from 'templates/2020/SEO';

import { ResourceAccessBlock } from 'components/common/ResourceAccessBlock';

export function Head(props: HeadProps) {
  return (
    <SEO
      title="High Quality Instructional Materials"
      description="2026 teacher planning resource"
      slug={props.location.pathname}
    />
  );
}

function HQIMPage(props: PageProps) {
  return (
    <PageLayout {...props}>
      <ResourceAccessBlock
        title="High Quality Instructional Materials"
        id="HQIM"
        subtitle=""
        redirectUrl="https://stileapp.com/go/HQIM_Checklist"
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/resources/HQIM_print.png"
            alt=""
          />
        }
      />
      <CtaBlock />
    </PageLayout>
  );
}

export default HQIMPage;
