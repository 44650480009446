import * as React from 'react';

import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { Button } from 'stile-shared/src/components/2020/common/Button';
import {
  Form,
  FormField,
  FormFieldError,
  Input,
  TextArea,
} from 'stile-shared/src/components/2020/common/Form';
import { Link } from 'stile-shared/src/components/2020/common/Link';
import { Text } from 'stile-shared/src/components/2020/common/Primitives';
import { P } from 'stile-shared/src/components/2020/common/Primitives';
import { Column } from 'stile-shared/src/components/2020/layout/Column';
import type {
  StileApiInboundLeads,
  StileApiLeadType,
  StileInboundLeadUs,
} from 'stile-shared/src/types/services';
import { isBrowser } from 'stile-shared/src/utils/isBrowser';
import { tagEvent } from 'stile-shared/src/utils/tagEvent';
import { emailValidator, phoneValidator } from 'stile-shared/src/utils/validators';

type FormData = Omit<StileInboundLeadUs['usContact'], 'leadType'>;

type TrialFormProps = {
  onSubmissionSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  leadType?: StileApiLeadType;
};

const scrollToTop = () => {
  if (isBrowser()) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }
};

export function TrialFormUs({ onSubmissionSuccess, leadType = 'GENERAL_CONTACT' }: TrialFormProps) {
  const {
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm<FormData>();

  const {
    mutate,
    isLoading,
    reset: resetMutation,
    isSuccess,
    isError,
  } = useMutation(createEnquiry, {
    onSuccess: () => {
      tagEvent('trial_form_submit_us');
      tagEvent('conversion', {
        send_to: 'AW-10860849256/j3dzCN7uq7wDEOjQ7boo',
      });
      resetForm();
      onSubmissionSuccess(true);
      setTimeout(scrollToTop, 300);
    },
    retry: 5,
  });

  const onSubmit = (formData: FormData) => {
    const submissionData: StileInboundLeadUs = {
      usContact: {
        ...formData,
        leadType: leadType,
      },
    };

    mutate(submissionData);
  };

  return isSuccess ? (
    <Column>
      <P>
        Fantastic! Thank you for requesting a free trial of Stile, we’ll be in touch with you
        shortly to arrange a time to get you up and running.
      </P>
      <P>
        In the meantime, you’re very welcome to preview our{' '}
        <Link
          url="https://stileapp.com/au/library/publishers/stile/compilations/science-ngss"
          openInNewTab
        >
          lesson library
        </Link>
        .
      </P>
    </Column>
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormField>
        <Input
          {...register('firstName', {
            maxLength: { value: 100, message: '100 character limit' },
            required: 'This field is required',
          })}
          label="First name*"
          placeholder="Melissa"
          aria-invalid={errors.firstName ? 'true' : 'false'}
        />
        <FormFieldError name="firstName" errors={errors} />
      </FormField>

      <FormField>
        <Input
          label="Last name*"
          placeholder="Rodriguez"
          aria-invalid={errors.lastName ? 'true' : 'false'}
          {...register('lastName', {
            maxLength: { value: 100, message: '100 character limit' },
            required: 'This field is required',
          })}
        />
        <FormFieldError name="lastName" errors={errors} />
      </FormField>

      <FormField>
        <Input
          type="text"
          label="School*"
          placeholder="Los Angeles Middle School"
          {...register('school', {
            maxLength: { value: 200, message: '200 character limit' },
            required: 'This field is required',
          })}
        />
      </FormField>

      <FormField>
        <Input
          label="District"
          placeholder="Los Angeles Unified School District"
          {...register('district', {
            maxLength: { value: 200, message: '200 character limit' },
          })}
        />
      </FormField>

      <FormField>
        <Input
          type="text"
          label="Zip code*"
          placeholder="90001"
          {...register('zipCode', {
            maxLength: { value: 20, message: '20 character limit' },
            required: 'This field is required',
            pattern: {
              value: /\d+/,
              message: 'Zip code invalid',
            },
          })}
        />
      </FormField>

      <FormField>
        <Input
          type="email"
          label="Email*"
          aria-invalid={errors.email ? 'true' : 'false'}
          placeholder="mrodriguez@usd.k12.gov"
          {...register('email', {
            maxLength: { value: 200, message: '200 character limit' },
            required: 'This field is required',
            pattern: {
              value: emailValidator,
              message: 'Email address invalid',
            },
          })}
        />
        <FormFieldError name="email" errors={errors} />
      </FormField>

      <FormField>
        <Input
          type="tel"
          label="Phone*"
          placeholder="213-555-2525"
          aria-invalid={errors.phone ? 'true' : 'false'}
          {...register('phone', {
            required: 'This field is required',
            pattern: {
              value: phoneValidator,
              message: 'Phone number invalid',
            },
          })}
        />
        <FormFieldError name="phone" errors={errors} />
      </FormField>

      <FormField>
        <Input
          label="When would it best suit you to meet?"
          placeholder="Thursday, May 4 at 2:40 PM (Pacific Time)"
          {...register('bestTimeToMeet', {
            maxLength: { value: 100, message: '100 character limit' },
          })}
        />
      </FormField>

      <FormField>
        <TextArea
          maxLength={5000}
          label="Anything in particular you’d like to learn more about?"
          {...register('additionalQueries')}
        />
      </FormField>

      {isError && (
        <Text color="error">
          Something went wrong submitting your details, please try again and if the issue persists{' '}
          <Link variant="inherit" url="/who-we-are/contact/">
            get in touch with us
          </Link>
          .
        </Text>
      )}

      <Button type="submit" onClick={resetMutation} disabled={isLoading}>
        {isLoading ? 'Submitting...' : 'Submit'}
      </Button>
    </Form>
  );
}

const createEnquiry = async (data: StileApiInboundLeads) => {
  const config = {
    headers: { 'Content-Type': 'application/json' },
  };

  const { data: response } = await axios.post(
    'https://stileapp.com/api/au/v3/inboundLeads',
    data,
    config
  );

  return response.data;
};
